.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

body {
  background-color: #FFF;
  font-family: Prodigysans, sans-serif;
  color: #1c212b;
  font-size: 18px;
  line-height: 1.6;
}

h1 {
  margin-top: 0px;
  margin-bottom: 32px;
  font-family: 'Fraunces', serif;
  font-size: 60px;
  line-height: 1.1;
  font-weight: 700;
}

h2 {
  margin-top: 0px;
  margin-bottom: 32px;
  font-family:  'Fraunces', serif;
  font-size: 50px;
  line-height: 1.1;
  font-weight: 500;
}

h3 {
  margin-top: 0px;
  margin-bottom: 32px;
  font-family:  'Fraunces', serif;
  font-size: 40px;
  line-height: 1.1;
  font-weight: 700;
}

h4 {
  margin-top: 0px;
  margin-bottom: 24px;
  font-family:  'Fraunces', serif;
  font-size: 32px;
  line-height: 1.1;
  font-weight: 400;
}

h5 {
  margin-top: 0px;
  margin-bottom: 24px;
  font-family:  'Fraunces', serif;
  font-size: 28px;
  line-height: 1.1;
  font-weight: 400;
}

h6 {
  margin-top: 0px;
  margin-bottom: 24px;
  font-family:  'Fraunces', serif;
  font-size: 24px;
  line-height: 1.1;
  font-weight: 400;
}

p {
  margin-bottom: 28px;
}

a {
  color: #1c212b;
  text-decoration: none;
}

ul {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
}

ol {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
}

img {
  max-width: 100%;
}

label {
  display: block;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
}

strong {
  font-weight: 600;
}

em {
  font-style: italic;
}

blockquote {
  margin-bottom: 28px;
  padding: 0px;
  border-left: 5px none #e2e2e2;
  font-family: 'Mayenne sans web', sans-serif;
  font-size: 32px;
  line-height: 1.1;
}

figure {
  margin-bottom: 10px;
}

figcaption {
  margin-top: 5px;
  text-align: center;
}

.section {
  padding-top: 150px;
  padding-bottom: 150px;
}

.section.footer {
  padding-top: 0px;
  padding-bottom: 80px;
  background-color: #1c212b;
  color: #fff;
}

.section.padding-0.contact-3-section {
  position: relative;
  background-color: #89725b;
  color: #fff;
}

.section.padding-0.contact-2-section {
  position: relative;
}

.section.overflow {
  overflow: hidden;
}

.section._404-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section.components-section {
  background-color: #dedbd9;
}

.container {
  width: 100%;
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.container.listings-cms {
  z-index: 10;
  margin-bottom: 60px;
}

.full-container {
  width: 100%;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 80px;
  padding-left: 80px;
}

.full-container.team-3d {
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.full-container.contact-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 160px;
  padding-bottom: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.full-container.z-index-15 {
  position: relative;
  z-index: 15;
}

.full-container.intro {
  padding-right: 0px;
  padding-left: 0px;
}

.column-small {
  width: 100%;
  max-width: 400px;
}

.column-small.template-pages {
  max-width: 510px;
  text-align: center;
}

.column-medium {
  width: 100%;
  max-width: 730px;
}

.column-medium.newsletter {
  max-width: 580px;
  text-align: center;
}

.grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid.spacer-80 {
  grid-row-gap: 80px;
}

.grid.spacer-12 {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

.grid.spacer-24 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.grid.faq-2 {
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
}

.grid.pop-up-newsletter {
  position: relative;
  padding: 80px 56px 56px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
}

.line {
  width: 100%;
  height: 1px;
  background-color: #e0dedc;
}

.display-1 {
  font-family: 'Mayenne sans web', sans-serif;
  font-size: 7vw;
}
 
.display-4 {
  font-size: 72px;
}

.grid-quarters {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-quarters.footer-photos {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.grid-quarters.partners {
  margin-top: 16px;
}

.grid-quarters.spacer-12px {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

.grid-quarters.spacer-12px.listings-sticky-grid {
  margin-top: 24px;
}

.paragraph-large {
  font-size: 24px;
  line-height: 1.4;
}

.text-3xl {
  font-family: 'Mayenne sans web', sans-serif;
  font-size: 32px;
  line-height: 1.2;
}

.text-3xl.reviews {
  line-height: 1.4;
}

.text-xl {
  font-size: 28px;
  line-height: 1.2;
}

.text-large {
  font-size: 24px;
  line-height: 1.2;
}

.text-large.review {
  line-height: 1.4;
}

.text-medium {
  font-size: 16px;
  line-height: 1.2;
}

.text-small {
  font-size: 14px;
  line-height: 1;
}

.text-small.popular {
  margin-right: 32px;
  margin-left: 32px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  opacity: 1;
}

.link-small {
  font-size: 14px;
  font-weight: 700;
}

.padding-bottom-0 {
  padding-bottom: 0px;
}

.grid-thirds {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-thirds.style-guide {
  grid-column-gap: 24px;
  grid-row-gap: 40px;
}

.grid-thirds.button-divider {
  -ms-grid-columns: 1fr 140px 1fr;
  grid-template-columns: 1fr 140px 1fr;
}

.grid-thirds.spacer-92 {
  grid-row-gap: 92px;
}

.grid-thirds.spacer-80 {
  grid-row-gap: 80px;
}

.grid-thirds.careers-stats {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.grid-thirds.spacer-24 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.grid-thirds.navbar-menu-bottom {
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-thirds.footer-bottom {
  margin-top: 47px;
  border-top: 1px solid #464646;
  padding-top: 29px;
}

.grid-thirds.licenses-grid {
  margin-top: 80px;
}

.bg-primary-1 {
  background-color: #1c212b;
}

.margin-top-12 {
  margin-top: 12px;
}

.bg-primary-2 {
  background-color: #3a4459;
}

.bg-secondary-1 {
  background-color: #89725b;
}

.bg-secondary-2 {
  background-color: #f0c79f;
}

.bg-neutral-1 {
  background-color: #f7f4f2;
}

.bg-neutral-1.style-guide-color.color-stroke {
  border-style: solid;
  border-width: 2px;
  border-color: #e0dedc;
}

.bg-neutral-2 {
  background-color: #dedbd9;
}

.bg-border {
  background-color: #e0dedc;
}

.bg-white {
  background-color: #fff;
}

.style-guide-color {
  height: 150px;
}

.button {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 140px;
  height: 140px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #89725b;
  border-radius: 50%;
  color: #89725b;
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
}

.button.careers-cms {
  position: absolute;
  bottom: 80px;
  background-color: #f7f4f2;
}

.button.stroke-white {
  border-color: #fff;
  color: #fff;
}

.button-arrow {
  position: absolute;
  top: 140px;
}

.button-secondary {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 140px;
  height: 140px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: #89725b;
  border-radius: 50%;
  -webkit-transition: color 600ms cubic-bezier(.23, 1, .32, 1), background-color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: color 600ms cubic-bezier(.23, 1, .32, 1), background-color 600ms cubic-bezier(.23, 1, .32, 1);
  color: #89725b;
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
}

.button-secondary:hover {
  background-color: #89725b;
  color: #f7f4f2;
}

.button-secondary.small {
  width: 80px;
  height: 80px;
  border-style: none;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
  -webkit-transition: background-color 600ms cubic-bezier(.23, 1, .32, 1), box-shadow 600ms cubic-bezier(.23, 1, .32, 1), -webkit-filter 600ms cubic-bezier(.23, 1, .32, 1);
  transition: background-color 600ms cubic-bezier(.23, 1, .32, 1), box-shadow 600ms cubic-bezier(.23, 1, .32, 1), -webkit-filter 600ms cubic-bezier(.23, 1, .32, 1);
  transition: filter 600ms cubic-bezier(.23, 1, .32, 1), background-color 600ms cubic-bezier(.23, 1, .32, 1), box-shadow 600ms cubic-bezier(.23, 1, .32, 1);
  transition: filter 600ms cubic-bezier(.23, 1, .32, 1), background-color 600ms cubic-bezier(.23, 1, .32, 1), box-shadow 600ms cubic-bezier(.23, 1, .32, 1), -webkit-filter 600ms cubic-bezier(.23, 1, .32, 1);
}

.button-secondary.small:hover {
  background-color: #a3a3a3;
  box-shadow: none;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.button-secondary.small.landing-3 {
  position: absolute;
  bottom: -40px;
}

.button-secondary-arrow {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.slider-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #89725b;
  border-radius: 50%;
}

.slider-arrow.left {
  left: auto;
  top: -120px;
  right: 60px;
  bottom: auto;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.slider-arrow.right {
  left: auto;
  top: -120px;
  right: 0%;
  bottom: auto;
}

.slider-arrow-icon {
  width: 24px;
  height: 24px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.button-cta {
  padding: 22px 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #89725b;
  -webkit-transition: -webkit-filter 600ms cubic-bezier(.23, 1, .32, 1);
  transition: -webkit-filter 600ms cubic-bezier(.23, 1, .32, 1);
  transition: filter 600ms cubic-bezier(.23, 1, .32, 1);
  transition: filter 600ms cubic-bezier(.23, 1, .32, 1), -webkit-filter 600ms cubic-bezier(.23, 1, .32, 1);
  color: #f7f4f2;
  font-size: 16px;
  line-height: 1;
  text-align: center;
}

.button-cta:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}

.button-cta.rounded {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 8px;
}

.custom-rich-text h1 {
  margin-bottom: 28px;
  font-family: Prodigysans, sans-serif;
  font-size: 32px;
  font-weight: 400;
}

.custom-rich-text h2 {
  margin-bottom: 28px;
  font-family: Prodigysans, sans-serif;
  font-size: 28px;
}

.custom-rich-text h3 {
  margin-bottom: 28px;
  font-family: Prodigysans, sans-serif;
  font-size: 24px;
}

.custom-rich-text h4 {
  font-family: Prodigysans, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.custom-rich-text h5 {
  font-family: Prodigysans, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.custom-rich-text h6 {
  font-family: Prodigysans, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.custom-rich-text figcaption {
  margin-top: 12px;
  color: #89725b;
  font-size: 14px;
  font-weight: 700;
}

.custom-rich-text figure {
  margin-top: 56px;
  margin-bottom: 56px;
}

.custom-rich-text ol {
  margin-bottom: 28px;
  padding-left: 20px;
}

.custom-rich-text ul {
  margin-bottom: 28px;
  padding-left: 0px;
  list-style-type: none;
}

.column-large {
  width: 100%;
  max-width: 1060px;
}

.text-center {
  text-align: center;
}

.margin-top-120 {
  margin-top: 120px;
}

.footer-link {
  margin-left: 34px;
  -webkit-transition: color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: color 600ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.footer-link:hover {
  color: #f0c79f;
}

.footer-link.first-item {
  margin-left: 0px;
}

.social-link {
  margin-left: 24px;
  -webkit-transition: color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: color 600ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.social-link:hover {
  color: #f0c79f;
}

.social-link.first-item {
  margin-left: 0px;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}

.margin-top-80 {
  margin-top: 80px;
}

.margin-top-16 {
  margin-top: 16px;
}

.template-link {
  -webkit-transition: color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: color 600ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.template-link:hover {
  color: #f0c79f;
}

.grid-halves {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 100%;
  height: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-halves.spacer-0 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.grid-halves.spacer-40-60 {
  grid-row-gap: 60px;
}

.grid-halves.about-grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1.25fr 1fr;
  grid-template-columns: 1.25fr 1fr;
}

.grid-halves.spacer-24 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.grid-halves.landing-1-hero {
  min-height: 900px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.padding-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.hero-halves-full-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.flex-stretch-space {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.flex-stretch-space.hero-halves-absolute {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 80px;
  width: 50%;
  padding-right: 80px;
  padding-left: 80px;
}

.flex-stretch-space.listing-2:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-stretch-space.landing-3-hero-bottom {
  margin-top: 40px;
}

.flex-stretch-space.reviews-2 {
  margin-top: 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-stretch-space.listing-1-wrap {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-stretch-space.listings-cms-content {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-stretch-space.footer-template-links-wrap {
  margin-top: 15px;
}

.display-2 {
  font-family: 'Mayenne sans web', sans-serif;
  font-size: 4vw;
}

.display-2 {
  font-size: 4vw;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-top-56 {
  margin-top: 56px;
}

.listing-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.column-xs {
  width: 100%;
  max-width: 320px;
}

.stats {
  font-family: 'Mayenne sans web', sans-serif;
  color: #89725b;
  font-size: 56px;
  line-height: 1.1;
}

.color-secondary-1 {
  color: #89725b;
}

.team-collection-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1020px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.team-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
}

.team-photo {
  width: 260px;
  max-width: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.collection-item {
  margin-right: 40px;
  margin-bottom: 80px;
  margin-left: 40px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.padding-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.ratings-icon {
  margin-right: 4px;
  margin-left: 4px;
}

.ratings-icon.small {
  width: 12px;
  margin-right: 2px;
  margin-left: 2px;
}

.ratings-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ratings-wrap.reviews {
  margin-top: 4px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.margin-top-40 {
  margin-top: 40px;
}

.grid-sixths {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-sixths.partners {
  margin-top: 16px;
}

.partner-logo {
  max-height: 30px;
}

.padding-top-0 {
  padding-top: 0px;
  padding-bottom: 160px;
}

.margin-top-8 {
  margin-top: 8px;
}

.bg-video-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1920px;
  min-height: 900px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(28, 33, 43, 0.5);
}

.color-secondary-2 {
  color: #f0c79f;
}

.landing-2-hero-bottom {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 80px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.listing-2-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 50%;
  padding-right: 100px;
  padding-left: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.listing-2-photo {
  width: 50%;
  height: 700px;
  -o-object-fit: cover;
  object-fit: cover;
}

.features-icon {
  width: 24px;
  margin-right: 8px;
}

.features {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 12px;
  margin-left: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.features.first-item {
  margin-left: 0px;
}

.features-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 8px;
}

.features-wrap.listing-1 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.text-2xl {
  font-family: 'Mayenne sans web', sans-serif;
  font-size: 24px;
  line-height: 1.2;
}

.stats-item {
  display: -ms-grid;
  display: grid;
  padding-top: 48px;
  padding-bottom: 48px;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px solid #e0dedc;
}

.stats-item.last-item {
  border-bottom: 1px solid #e0dedc;
}

.about-card {
  margin-left: -80px;
  padding: 80px 56px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
}

.about-image {
  width: 100%;
  height: 830px;
  max-width: 730px;
  -o-object-fit: cover;
  object-fit: cover;
}

.reviews-slider {
  width: 100%;
  height: auto;
  margin-top: 80px;
  background-color: hsla(0, 0%, 86.7%, 0);
}

.reviews-mask {
  overflow: visible;
  width: 400px;
}

.reviews-slide {
  width: 400px;
  margin-right: 40px;
  padding: 56px 40px 40px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
}

.reviews-avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.flex-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-center.listing-code {
  margin-left: 24px;
}

.flex-center.details-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.reviews-content {
  margin-left: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.reviews-slide-nav {
  display: none;
}

.listing-2-button {
  position: absolute;
  bottom: 56px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #89725b;
}

.listing-2-arrow {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.landing-3-hero {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 750px;
  margin-top: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(28, 33, 43, 0.2)), to(rgba(28, 33, 43, 0.2))), url('../images/main-image---home-hero-3.jpg');
  background-image: linear-gradient(180deg, rgba(28, 33, 43, 0.2), rgba(28, 33, 43, 0.2)), url('../images/main-image---home-hero-3.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  color: #fff;
}

.landing-3-hero.template-pages {
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(28, 33, 43, 0.5)), to(rgba(28, 33, 43, 0.5))), url('../images/main-image---home-hero-3.jpg');
  background-image: linear-gradient(180deg, rgba(28, 33, 43, 0.5), rgba(28, 33, 43, 0.5)), url('../images/main-image---home-hero-3.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.listing-card-2 {
  height: 100%;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
  -webkit-transition: box-shadow 600ms cubic-bezier(.23, 1, .32, 1);
  transition: box-shadow 600ms cubic-bezier(.23, 1, .32, 1);
  border: 1px solid #DDDDDD;
}

.listing-card-2:hover {
  box-shadow: 0 16px 32px 0 rgba(137, 114, 91, 0.16);
}

.listing-card-2.max-400 {
  width: 100%;
  max-width: 400px;
}

.listing-content-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 32px 30px 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between; 
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #fff;
  text-align: center;
}

.listing-2-card-top {
  position: relative;
}

/*.listing-2-price {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  padding: 9px 24px 9px 32px;
  background-color: #89725b;
  color: #fff;
}*/
.listing-2-price {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  padding: 9px 24px 9px 32px;
  background-color: #16181C;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500; 
  font-size: 18px;
  line-height: normal;
  color: #FFFFFF;
  min-height: 56px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.listing-2-price-text { 
  font-family: Prodigysans, sans-serif;
}



.margin-top-4 {
  margin-top: 4px;
}

.listing-card-2-photo {
  -o-object-fit: cover;
  object-fit: cover;
}

.listing-2-card-features {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  color: #89725b;
}

.stats-2-item {
  display: -ms-grid;
  display: grid;
  padding-top: 48px;
  padding-bottom: 48px;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px solid #e0dedc;
}

.stats-2-item.last-item {
  border-bottom: 1px solid #e0dedc;
}

.stats-2-title {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.gallery-slider {
  width: 100%;
  height: 800px;
  background-color: hsla(0, 0%, 86.7%, 0);
}

.gallery-slide-nav {
  display: none;
}

.gallery-slide-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.gallery-slider-mask {
  overflow: visible;
  width: 100%;
  height: 800px;
}

.margin-top-20 {
  margin-top: 20px;
}

.gallery-arrow {
  left: auto;
  top: auto;
  right: 0%;
  bottom: -30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.gallery-arrow.left {
  right: 36px;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.team-card-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.partner-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 140px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #e0dedc;
  border-radius: 1px;
}

.reviews-2-slider {
  width: 950px;
  height: 100%;
  background-color: hsla(0, 0%, 86.7%, 0);
}

.reviews-2-slider-mask {
  overflow: visible;
  width: 950px;
}

.reviews-2-slide {
  margin-right: 40px;
  padding: 64px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
}

.reviews-2-avatar {
  width: 48px;
  height: 48px;
  margin-right: 12px;
  border-radius: 50%;
}

.reviews-2-slide-nav {
  display: none;
}

.overflow {
  overflow: hidden;
}

.right-arrow {
  left: auto;
  top: auto;
  right: 0%;
  bottom: -41px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.left-arrow {
  left: auto;
  top: auto;
  right: 36px;
  bottom: -41px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.about-hero-image {
  width: 10vw;
  height: 10vh;
  min-width: 120px;
  margin-right: 32px;
  margin-left: 32px;
  border-radius: 100vw;
  -o-object-fit: cover;
  object-fit: cover;
}

.about-hero-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Mayenne sans web', sans-serif;
  font-size: 3.5vw;
}

.about-heading {
  font-family: Prodigysans, sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.about-bg-video {
  width: 100%;
  height: 800px;
}

.scroll-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: 'Mayenne sans web', sans-serif;
  font-size: 40px;
  line-height: 1.1;
}

.scroll-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.diamon-divider {
  margin-right: 16px;
  margin-left: 16px;
}

.scroll-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 80px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #e0dedc;
  border-bottom: 1px solid #e0dedc;
}

.button-career {
  position: absolute;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 140px;
  height: 140px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: #89725b;
  border-radius: 50%;
  background-color: #f7f4f2;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
  -webkit-transition: color 600ms cubic-bezier(.23, 1, .32, 1), background-color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: color 600ms cubic-bezier(.23, 1, .32, 1), background-color 600ms cubic-bezier(.23, 1, .32, 1);
  color: #89725b;
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
}

.button-career:hover {
  background-color: #89725b;
  color: #f7f4f2;
}

.about-review-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.hero-listing-1 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1920px;
  min-height: 900px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 80px;
  padding-left: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1c212b;
  color: #fff;
  text-align: center;
}

.hero-listing-1-top {
  position: absolute;
  left: 0%;
  top: 100px;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 80px;
  margin-left: 80px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-listing-1-line {
  width: 100%;
  height: 1px;
  background-color: rgba(137, 114, 91, 0.5);
}

.listing-1-filter {
  width: 100%;
  max-width: 360px;
  min-width: 300px;
  margin-right: 40px;
  padding: 40px 24px 24px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #dedbd9;
}

.newsletter-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 160px;
  padding-bottom: 160px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #dedbd9;
}

.newsletter-form-wrap {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 0px;
}

.newsletter-form {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 2fr 2fr 1fr;
  grid-template-columns: 2fr 2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-field {
  height: 50px;
  margin-bottom: 0px;
  padding: 0px 24px;
  border: 1px none #000;
  line-height: 1;
}

.text-field::-webkit-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.text-field:-ms-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.text-field::-ms-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.text-field::placeholder {
  color: #1c212b;
  font-size: 16px;
}

.text-field.large {
  height: 60px;
}

.text-field.large.stroke {
  border-style: solid;
  border-color: #e0dedc;
}

.text-field.large.stroke::-webkit-input-placeholder {
  text-align: center;
}

.text-field.large.stroke:-ms-input-placeholder {
  text-align: center;
}

.text-field.large.stroke::-ms-input-placeholder {
  text-align: center;
}

.text-field.large.stroke::placeholder {
  text-align: center;
}

.text-field.search-field {
  background-color: #fff;
}

.text-field.overlay-field {
  height: 60px;
  padding-right: 0px;
  padding-left: 0px;
  border-bottom-style: solid;
  border-bottom-color: #3a4459;
  background-color: hsla(0, 0%, 100%, 0);
  -webkit-transition: border-color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: border-color 600ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 16px;
}

.text-field.overlay-field:focus {
  border-bottom-color: #53617f;
}

.text-field.overlay-field::-webkit-input-placeholder {
  color: #fff;
}

.text-field.overlay-field:-ms-input-placeholder {
  color: #fff;
}

.text-field.overlay-field::-ms-input-placeholder {
  color: #fff;
}

.text-field.overlay-field::placeholder {
  color: #fff;
}

.button-newletter {
  padding: 22px 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1c212b;
  -webkit-transition: background-color 600ms cubic-bezier(.23, 1, .32, 1), -webkit-filter 600ms cubic-bezier(.23, 1, .32, 1);
  transition: background-color 600ms cubic-bezier(.23, 1, .32, 1), -webkit-filter 600ms cubic-bezier(.23, 1, .32, 1);
  transition: background-color 600ms cubic-bezier(.23, 1, .32, 1), filter 600ms cubic-bezier(.23, 1, .32, 1);
  transition: background-color 600ms cubic-bezier(.23, 1, .32, 1), filter 600ms cubic-bezier(.23, 1, .32, 1), -webkit-filter 600ms cubic-bezier(.23, 1, .32, 1);
  color: #f7f4f2;
  font-size: 16px;
  line-height: 1;
  text-align: center;
}

.button-newletter:hover {
  background-color: #3a4459;
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
}

.button-newletter.small {
  padding: 8px 24px 12px;
  font-size: 20px;
}

.dropdown {
  width: 100%;
}

.dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0px 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  font-size: 16px;
  line-height: 1;
}

.filters-dropdown-list.w--open {
  position: static;
  padding: 20px 20px 8px;
  border-top: 1px solid #e0dedc;
  background-color: #fff;
}

.filters-dropdown-list.listing-2 {
  position: absolute;
}

.filter-form {
  margin-bottom: 0px;
}

.listing-1-filter-wrap {
  margin-top: 24px;
}

.listing-2-filter {
  width: 100%;
  max-width: 100%;
  min-width: 250px;
  padding: 24px 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #dedbd9;
}

.search-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #C1BEBA;
border-radius: 4px;
overflow: hidden;
position: relative;
}

.search-wrap .search_icon{position: absolute;
  left: 12px;font-size: 15px;}
  .search-wrap .text-field {
    padding-left: 11px;
  } 

.listing-2-filter-holder {
  margin-top: 0px;
}

.newsletter-image {
  width: 100%;
  height: 640px;
  -o-object-fit: cover;
  object-fit: cover;
}

.newsletter-2-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #dedbd9;
}

.newsletter-2-field-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 510px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.newsletter-2-submit-wrap {
  position: absolute;
  left: auto;
  right: 10px;
}

.text-4xl {
  font-family: 'Mayenne sans web', sans-serif;
  font-size: 40px;
  line-height: 1.1;
}

.drop-shadow {
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
}

.team-card-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 50%;
  background-color: rgba(28, 33, 43, 0.4);
}

.team-card-hover {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #89725b;
}

.team-card-hover-icon {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.listings-cms-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 640px;
  -o-object-fit: cover;
  object-fit: cover;
}

.text-featured {
  color: #89725b;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
}

.text-featured.property-type {
  margin-left: 8px;
}

.listings-cms-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.listings-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 140px;
  width: 100%;
  max-width: 400px;
  margin-left: 80px;
  padding: 32px 24px 24px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
}

.side_bar.listings_sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 140px;
  width: 100%;
  max-width: 400px; 
  margin-left: 80px;
  
}
.side_bar .white_box{padding: 32px 24px 24px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);}
.subtitle {
  margin-bottom: 28px;
  font-family: Prodigysans, sans-serif;
  font-size: 28px;
}

.amenities-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.amenities-wrap.amenities-cms-page {
  margin-top: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.amenities-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 9px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
  color: #89725b;
  font-size: 16px;
  line-height: 1.2;
}

.amenities-icon {
  width: 32px;
  margin-right: 8px;
}

.agents-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #e0dedc;
  border-radius: 16px;
  background-color: rgba(224, 222, 220, 0);
  -webkit-transition: background-color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: background-color 600ms cubic-bezier(.23, 1, .32, 1);
  text-align: center;
}

.agents-item:hover {
  background-color: rgba(224, 222, 220, 0.32);
}

.agents-avatar {
  width: 100%;
  max-width: 180px;
  margin-bottom: 16px;
  border-radius: 50%;
}

.google-maps-wrap {
  overflow: hidden;
  border-radius: 16px;
}

.listings-sticky-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.google-maps-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ebe9e6;
  border-radius: 8px;
  background-color: rgba(224, 222, 220, 0);
  -webkit-transition: background-color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: background-color 600ms cubic-bezier(.23, 1, .32, 1);
}

.google-maps-icon:hover {
  background-color: rgba(224, 222, 220, 0.32);
}

.listings-thumbs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.listings-sticky-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ebe9e6;
  border-radius: 8px;
  color: #89725b;
  font-size: 16px;
}

.listings-sticky-icon {
  width: 24px;
  margin-right: 8px;
}

.listings-sticky-icon.social {
  width: auto;
  margin-right: 0px;
}

.news-collection-item {
  width: 47%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.news-collection-item:nth-child(even) {
  margin-top: 200px;
}

.news-item {
  height: auto;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
  -webkit-transition: box-shadow 600ms cubic-bezier(.23, 1, .32, 1);
  transition: box-shadow 600ms cubic-bezier(.23, 1, .32, 1);
}

.news-item:hover {
  box-shadow: 0 16px 32px 0 rgba(137, 114, 91, 0.16);
}

.news-item.no-shadow {
  box-shadow: none;
}

.news-thumb {
  width: 100%;
  height: 620px;
  -o-object-fit: cover;
  object-fit: cover;
}

.news-info {
  height: auto;
  padding: 40px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #fff;
}

.news-collection-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.newsletter-form-wrap-2 {
  width: 100%;
  max-width: 700px;
  margin-top: 16px;
  margin-bottom: 0px;
}

.newsletter-form-2 {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.newsletter-wrap-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #dedbd9;
}

.news-cms-image {
  width: 100%;
  height: 700px;
  margin-top: 160px;
  -o-object-fit: cover;
  object-fit: cover;
}

.news-cms-item {
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
}

.careers-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 80px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.careers-image {
  margin-right: 10px;
  margin-left: 10px; 
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.careers-image._3 {
  width: 550px;
}

.stats-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 60px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.stats-block.block-1 {
  border-top: 1px solid #e0dedc;
  border-bottom: 1px solid #e0dedc;
  border-left: 1px solid #e0dedc;
}

.stats-block.block-2 {
  border-top: 1px solid #e0dedc;
  border-right: 1px solid #e0dedc;
  border-bottom: 1px solid #e0dedc;
}

.stats-block.block-3 {
  padding-right: 40px;
  padding-left: 40px;
  border-style: solid;
  border-width: 1px;
  border-color: #e0dedc;
  text-align: center;
}

.stats-block.block-4 {
  border-bottom: 1px solid #e0dedc;
  border-left: 1px solid #e0dedc;
}

.stats-block.block-5 {
  border-right: 1px solid #e0dedc;
  border-bottom: 1px solid #e0dedc;
}

.careers-card {
  width: 100%;
  padding: 25px 32px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
  -webkit-transition: background-color 600ms cubic-bezier(.23, 1, .32, 1), box-shadow 600ms cubic-bezier(.23, 1, .32, 1);
  transition: background-color 600ms cubic-bezier(.23, 1, .32, 1), box-shadow 600ms cubic-bezier(.23, 1, .32, 1);
}

.careers-card:hover {
  background-color: rgba(222, 219, 217, 0.8);
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0);
}

.faq-item {
  width: 100%;
  margin-top: -1px;
  border-top: 1px solid #e0dedc;
  border-bottom: 1px solid #e0dedc;
}

.faq-question {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 23px;
  padding-bottom: 23px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.faq-answer {
  overflow: hidden;
  width: 100%;
}

.faq-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-left: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: #89725b;
  border-radius: 50%;
}

.faq-icon-bar {
  position: absolute;
  width: 2px;
  height: 16px;
  background-color: #89725b;
}

.faq-icon-bar.horizontal {
  width: 16px;
  height: 2px;
}

.hero-vertical {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 900px;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.careers-cms-image {
  opacity: 0.6;
  -o-object-fit: cover;
  object-fit: cover;
}

.careers-cms-image.image-1 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 25%;
  height: 50%;
}

.careers-cms-image.image-2 {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: 25%;
  height: 66%;
}

.team-cms-photo {
  position: relative;
  width: 620px;
  height: 620px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.team-name {
  position: absolute;
}

.team-name.back {
  z-index: -1;
  white-space: nowrap;
}

.team-name.front {
  z-index: 10;
  color: #f7f4f2;
  white-space: nowrap;
}

.team-cms-photo-mask {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.display-3 {
  font-size: 96px;
}

.hero-vertical-footer {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0px;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.hero-vertical-footer.contact-2 {
  bottom: 80px;
}

.team-cms-light {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.1;
  -webkit-filter: blur(60px);
  filter: blur(60px);
}

.grid-fifths {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-fifths.team-social {
  margin-top: 24px;
}

.listings-sticky-social-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ebe9e6;
  border-radius: 8px;
  background-color: rgba(224, 222, 220, 0);
  -webkit-transition: background-color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: background-color 600ms cubic-bezier(.23, 1, .32, 1);
  color: #89725b;
  font-size: 16px;
}

.listings-sticky-social-item:hover {
  background-color: rgba(224, 222, 220, 0.32);
}

.faq-question-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 23px 32px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.faq-answer-2 {
  overflow: hidden;
  width: 100%;
  padding-right: 32px;
  padding-left: 32px;
}

.faq-item-2 {
  width: 100%;
  margin-top: -1px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
}

.relative {
  position: relative;
}

.contact-2-bg {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: 50%;
  height: 100%;
  opacity: 0.4;
  -o-object-fit: cover;
  object-fit: cover;
}

.contact-form-2 {
  padding: 80px 48px 48px;
  background-color: #fff;
}

.contact-text-field-2 {
  height: 60px;
  margin-bottom: 0px;
  padding: 0px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #e0dedc;
  font-size: 16px;
}

.contact-text-field-2::-webkit-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-field-2:-ms-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-field-2::-ms-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-field-2::placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-select-field-2 {
  margin-bottom: 0px;
  padding: 0px 0px 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  background-color: hsla(0, 0%, 95.3%, 0);
  font-family: Prodigysans, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.contact-select-field-wrap-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  padding-right: 17px;
  padding-left: 17px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #e0dedc;
}

.contact-text-area-2 {
  min-height: 150px;
  padding: 15px 20px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #e0dedc;
  color: #1c212b;
  font-size: 16px;
}

.contact-text-area-2::-webkit-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-area-2:-ms-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-area-2::-ms-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-area-2::placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-3-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 50%;
  height: 66%;
  opacity: 0.5;
  -o-object-fit: cover;
  object-fit: cover;
}

.contact-3-image.image-2 {
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 25%;
  height: 33%;
}

.z-index-15 {
  position: relative;
  z-index: 15;
}

.contact-text-field-3 {
  height: 60px;
  margin-bottom: 0px;
  padding: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(224, 222, 220, 0) rgba(224, 222, 220, 0) #e0dedc;
  background-color: hsla(0, 0%, 100%, 0);
  font-size: 16px;
}

.contact-text-field-3::-webkit-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-field-3:-ms-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-field-3::-ms-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-field-3::placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-area-3 {
  overflow: hidden;
  max-height: 60px;
  padding: 19px 0px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(224, 222, 220, 0) rgba(224, 222, 220, 0) #e0dedc;
  background-color: hsla(0, 0%, 100%, 0);
  color: #1c212b;
  font-size: 16px;
}

.contact-text-area-3::-webkit-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-area-3:-ms-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-area-3::-ms-input-placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-text-area-3::placeholder {
  color: #1c212b;
  font-size: 16px;
}

.contact-select-field-3 {
  height: 60px;
  margin-bottom: 0px;
  padding: 0px 0px 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #f7f4f2 #f7f4f2 #e0dedc;
  background-color: hsla(0, 0%, 95.3%, 0);
  font-family: Prodigysans, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.contact-form-3 {
  padding: 80px 60px 60px;
  border-style: solid;
  border-width: 1px;
  border-color: #e0dedc;
}

.privacy-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.privacy-date {
  margin-right: 40px;
  margin-left: 40px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #89725b;
}

.top-bar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 61px;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #89725b;
  color: #fff;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
}

.close-top-bar {
  position: absolute;
  left: auto;
  top: 50%;
  right: 17px;
  bottom: auto;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  cursor: pointer;
}

.pop-up {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10000;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(222, 219, 217, 0.5);
}

.pop-up-close {
  position: absolute;
  left: auto;
  top: 24px;
  right: 24px;
  bottom: auto;
  cursor: pointer;
}

.navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 1000;
  width: 100%;
  height: 128px;
}

.navbar-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 90px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-container {
  width: 100%;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 32px;
  padding-left: 32px;
}

.navbar-icon {
  position: relative;
  z-index: 9000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 14px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.navbar-icon-bar {
  width: 100%;
  height: 2px;
  background-color: #1c212b;
}

.navbar-menu {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 8000;
  display: none;
  width: 100%;
  height: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-menu-wrap {
  position: relative;
  z-index: 8500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 60px 4%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;

  max-height: calc(100vh - 0px);
overflow-y: auto;
background-color: #1c212b;
}
.navbar-menu-links-wrap > .w100p.text-center {
  margin-bottom: 30px;
} 
.grid-thirds.navbar-menu-bottom {
  margin-top: 30px;
} 

.navbar-menu-photo-mask {
  position: relative;
  z-index: 8500;
  width: 50%;
  height: 100%;
}

.navbar-menu-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -2;
  overflow: hidden;
  width: 100%;
  background-color: #1c212b;
}

.navbar-menu-photo {
  width: 100%;
  height: 100%;
  background-image: url('../images/Menu-BG.jpg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
  -o-object-fit: cover;
  object-fit: cover;
}

.navbar-menu-links-wrap {
  width: 100%;
}

.navbar-menu-social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.navbar-menu-link-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 28px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-menu-link-row.last-item {
  margin-bottom: 0px;
}

.navbar-menu-line {
  width: 100%;
  height: 1px;
  background-color: #89725b;
  opacity: 0.5;
}

.navbar-menu-link {
  font-family: 'Fraunces', serif;
  color: #fff;
  font-size: 22px;
  line-height: 1;
  white-space: nowrap;
}
.navbar-menu-link-text a {
 font-family: 'Fraunces', serif;
  color: #fff;
  font-size: 22px;
  line-height: 1;
  }

.navbar-menu-link-text {
  margin-right: 40px;
  margin-left: 40px;
  -webkit-transition: color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: color 600ms cubic-bezier(.23, 1, .32, 1);
  font-size: 22px;
  white-space: nowrap; 
}

.navbar-menu-link-text:hover {
  color: #89725b;
}

.navbar-menu-social-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 12px;
  margin-left: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-menu-link-mask {
  overflow: hidden;
}

.navbar-menu-bottom-text {
  font-size: 14px;
  line-height: 1;
}

.listings-image-lightbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 640px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.button-lightbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 18px 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1c212b;
  -webkit-transition: background-color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: background-color 600ms cubic-bezier(.23, 1, .32, 1);
  color: #f7f4f2;
  font-size: 16px;
  line-height: 1;
  text-align: center;
}

.button-lightbox:hover {
  background-color: #3a4459;
}

.lightbox-icon {
  margin-right: 8px;
}

.contact-overlay {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 20000;
  display: none;
  overflow: auto;
  width: 50%;
  padding: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: #1c212b;
}

.contact-overlay-top {
  position: relative;
  color: #fff;
}

.close-overlay {
  position: absolute;
  left: auto;
  top: 40px;
  right: 40px;
  bottom: auto;
  cursor: pointer;
}

.overlay-form {
  margin-top: 56px;
  margin-bottom: 0px;
  color: #fff;
}

.select-field.overlay-field {
  height: 60px;
  margin-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #3a4459;
  background-color: hsla(0, 0%, 95.3%, 0);
  color: #fff;
  font-size: 16px;
}

.select-field.overlay-field:active {
  color: #fff;
}

.select-field.overlay-field:focus {
  color: #3a4459;
}

.overlay-textarea {
  overflow: hidden;
  max-height: 60px;
  margin-bottom: 0px;
  padding: 19px 0px;
  border-style: none none solid;
  border-width: 0px 0px 1px;
  border-color: #000 #000 #3a4459;
  background-color: hsla(0, 0%, 100%, 0);
  -webkit-transition: border-color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: border-color 600ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 16px;
}

.overlay-textarea:focus {
  border-bottom-style: solid;
  border-bottom-color: #4b5873;
}

.overlay-textarea::-webkit-input-placeholder {
  color: #fff;
  font-size: 16px;
}

.overlay-textarea:-ms-input-placeholder {
  color: #fff;
  font-size: 16px;
}

.overlay-textarea::-ms-input-placeholder {
  color: #fff;
  font-size: 16px;
}

.overlay-textarea::placeholder {
  color: #fff;
  font-size: 16px;
}

.success-message {
  background-color: #89725b;
  font-size: 16px;
}

.success-message.light-message {
  color: #f7f4f2;
}

.error-message {
  margin-top: 16px;
  padding: 12px 16px;
  background-color: #593a3a;
  font-size: 16px;
  text-align: center;
}

.error-message.light-error {
  color: #f7f4f2;
}

.checkbox {
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin-right: 8px;
  margin-left: -20px;
  border-radius: 6px;
  -webkit-transition: background-color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: background-color 600ms cubic-bezier(.23, 1, .32, 1);
}

.checkbox.w--redirected-checked {
  border-style: none;
  background-color: #89725b;
  background-size: 10px;
}

.checkbox.w--redirected-focus {
  box-shadow: none;
}

.checkbox-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 12px;
  margin-bottom: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.filters-listing-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.filters-form {
  margin-bottom: 0px;
}

.showcase-image {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.showcase-mask {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 1100px;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

.about-wrap {
  margin-top: 80px;
}

.slider-text {
  margin-top: 20px;
}

.contact-label {
  margin-bottom: 12px;
}

.listing-thumbs-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.licenses-image {
  width: 100%;
  height: 240px;
  -webkit-transition: -webkit-filter 600ms cubic-bezier(.23, 1, .32, 1);
  transition: -webkit-filter 600ms cubic-bezier(.23, 1, .32, 1);
  transition: filter 600ms cubic-bezier(.23, 1, .32, 1);
  transition: filter 600ms cubic-bezier(.23, 1, .32, 1), -webkit-filter 600ms cubic-bezier(.23, 1, .32, 1);
  -o-object-fit: cover;
  object-fit: cover;
}

.licenses-image:hover {
  -webkit-filter: grayscale(90%);
  filter: grayscale(90%);
}

.column-xl {
  width: 100%;
  max-width: 1060px;
}

.licenses-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
  -webkit-transition: box-shadow 600ms cubic-bezier(.23, 1, .32, 1);
  transition: box-shadow 600ms cubic-bezier(.23, 1, .32, 1);
}

.licenses-card:hover {
  box-shadow: 0 16px 32px 0 rgba(137, 114, 91, 0.16);
}

.prodigy-sans {
  font-family: Prodigysans, sans-serif;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;
}

.licenses-link {
  font-weight: 600;
}

.changelog {
  display: -ms-grid;
  display: grid;
  padding-top: 48px;
  padding-bottom: 48px;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px solid #e0dedc;
  border-bottom: 1px solid #e0dedc;
}

.intro-label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100vw;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
}

.screenshots-wrap {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1070px;
  margin-top: 80px;
  margin-right: auto;
  margin-left: auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 80px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  text-align: center;
}

.screeshots-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
  padding: 56px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #dedbd9;
  box-shadow: 0 6px 12px 0 rgba(241, 240, 245, 0.6);
  -webkit-transition: background-color 600ms cubic-bezier(.215, .61, .355, 1);
  transition: background-color 600ms cubic-bezier(.215, .61, .355, 1);
}

.screeshots-item:hover {
  background-color: rgba(137, 114, 91, 0.4);
}

.screenshot-img {
  width: 403px;
  box-shadow: 0 16px 32px 0 rgba(15, 13, 25, 0.08);
}

.components-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 9px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(137, 114, 91, 0.04);
  -webkit-transition: box-shadow 600ms cubic-bezier(.215, .61, .355, 1), background-color 600ms cubic-bezier(.23, 1, .32, 1);
  transition: box-shadow 600ms cubic-bezier(.215, .61, .355, 1), background-color 600ms cubic-bezier(.23, 1, .32, 1);
  color: #89725b;
  font-size: 16px;
  line-height: 1.2;
}

.components-item:hover {
  background-color: rgba(222, 219, 217, 0.8);
  box-shadow: none;
}

.components-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 56px;
  }

  h2 {
    font-size: 44px;
  }

  h3 {
    font-size: 36px;
  }

  blockquote {
    font-size: 28px;
  }

  .section {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .section.padding-bottom-0 {
    padding-bottom: 0px;
  }

  .section.padding-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .section.padding-top-0 {
    padding-top: 0px;
  }

  .section.reviews-section {
    padding-bottom: 160px;
  }

  .section.section-slider {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .container {
    padding-right: 24px;
    padding-left: 24px;
  }

  .full-container {
    padding-right: 24px;
    padding-left: 24px;
  }

  .full-container.landing-1 {
    padding-top: 160px;
    padding-bottom: 120px;
  }

  .grid.newsletter-grid {
    margin-top: 80px;
  }

  .display-4 {
    font-size: 56px;
  }

  .grid-quarters.footer-photos {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid-quarters.partners {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid-thirds {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-thirds.listing-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-thirds.spacer-92 {
    grid-row-gap: 40px;
  }

  .grid-thirds.footer-bottom {
    margin-top: 60px;
    grid-row-gap: 12px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-thirds.team-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-thirds.licenses-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .button.careers-cms {
    display: none;
  }

  .button.careers-cms.w--current {
    position: static;
    margin-top: 0px;
  }

  .slider-arrow.left {
    left: -60px;
    top: auto;
    right: 0%;
    bottom: -24%;
  }

  .slider-arrow.right {
    left: 60px;
    top: auto;
    right: 0%;
    bottom: -24%;
  }

  .grid-halves {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-halves.spacer-40-60.listing-1 {
    margin-top: 0px;
  }

  .grid-halves.about-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-halves.listing-1 {
    margin-top: 80px;
  }

  .grid-halves.landing-1-hero {
    min-height: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .flex-stretch-space.hero-halves-absolute {
    position: static;
    width: 100%;
    margin-top: 80px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .flex-stretch-space.listing-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-stretch-space.listing-2:nth-child(even) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-stretch-space.listing-1-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .flex-stretch-space.listings-cms-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .display-2 {
    font-size: 56px;
  }

  .stats {
    font-size: 44px;
  }

  .grid-sixths.partners {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .listing-card {
    width: 100%;
    max-width: 730px;
  }

  .bg-video-hero {
    min-height: 780px;
  }

  .listing-2-content {
    width: 100%;
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .listing-2-photo {
    width: 100%;
    height: auto;
  }

  .about-card {
    width: 100%;
    max-width: 730px;
    margin-left: 0px;
  }

  .about-image {
    height: auto;
  }

  .reviews-slider {
    margin-top: 56px;
  }

  .reviews-mask {
    width: 100%;
    height: auto;
    max-width: 100%;
  }

  .reviews-slide {
    width: 100%;
    height: auto;
    max-width: 730px;
  }

  .listing-2-button {
    position: static;
    margin-top: 40px;
  }

  .landing-3-hero.template-pages {
    height: 650px;
  }

  .listing-card-2 {
    width: 100%;
    max-width: 400px;
  }

  .stats-2-item {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .gallery-slider {
    height: 600px;
  }

  .gallery-slider-mask {
    height: 600px;
  }

  .reviews-2-slider {
    width: 100%;
    height: auto;
  }

  .reviews-2-slider-mask {
    width: 100%;
  }

  .about-hero-image {
    height: 80px;
  }

  .about-hero-line {
    font-size: 4.5vw;
  }

  .about-review-image {
    height: auto;
  }

  .hero-listing-1 {
    min-height: auto;
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .listing-1-filter {
    max-width: 730px;
    margin-right: 0px;
  }

  .newsletter-form {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .newsletter-image {
    height: auto;
    max-width: 730px;
    margin-top: 40px;
  }

  .newsletter-2-wrap {
    width: 100%;
    max-width: 730px;
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .listings-sticky {
    position: static;
    margin-top: 40px;
    margin-left: 0px;
  }

  .listings-thumbs {
    width: 100%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .news-collection-item {
    width: 100%;
    margin-bottom: 80px;
  }

  .news-collection-item:nth-child(odd) {
    width: 100%;
  }

  .news-collection-item:nth-child(even) {
    width: 100%;
    margin-top: 0px;
  }

  .news-collection-item:last-child {
    margin-bottom: 0px;
  }

  .news-item {
    width: 100%;
  }

  .news-thumb {
    height: auto;
  }

  .news-collection-list {
    width: 100%;
    max-width: 730px;
  }

  .news-cms-image {
    height: auto;
    margin-top: 80px;
  }

  .careers-image._1 {
    display: none;
  }

  .careers-image._2 {
    width: 30%;
  }

  .careers-image._3 {
    width: 50%;
  }

  .careers-image._4 {
    width: 30%;
  }

  .careers-image._5 {
    display: none;
  }

  .faq-icon {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .hero-vertical {
    min-height: auto;
    padding-top: 160px;
    padding-bottom: 120px;
  }

  .hero-vertical.careers-cms {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .display-3 {
    font-size: 56px;
  }

  .hero-vertical-footer.contact-2 {
    position: static;
    margin-top: 80px;
  }

  .faq-item-2 {
    max-width: 730px;
  }

  .navbar-container {
    padding-right: 24px;
    padding-left: 24px;
  }

  .navbar-menu-wrap {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
  }

  .navbar-menu-photo-mask {
    display: none;
  }

  .navbar-menu-bg {
    width: 100%;
  }

  .contact-overlay {
    width: 100%;
    max-width: 100%;
    padding-right: 40px;
    padding-left: 40px;
  }

  .showcase-mask {
    height: 800px;
  }

  .about-wrap {
    margin-top: 60px;
  }

  .careers-wrap {
    width: 100%;
    max-width: 730px;
    margin-right: auto;
    margin-left: auto;
  }

  .contact-1-hero-bottom {
    margin-top: 56px;
  }

  .news-wrap {
    width: 100%;
    max-width: 730px;
  }

  .listing-thumbs-wrap {
    -webkit-box-flex: 0;
    -webkit-flex: 0 33.33%;
    -ms-flex: 0 33.33%;
    flex: 0 33.33%;
  }

  .licenses-card {
    width: 100%;
    max-width: 730px;
  }

  .changelog {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .screenshots-wrap {
    padding-right: 40px;
    padding-left: 40px;
  }

  .screenshots-wrap.get-started {
    padding-right: 0px;
    padding-left: 0px;
  }

  .screeshots-item {
    padding: 24px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 30px;
  }

  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section.reviews-section {
    padding-bottom: 160px;
  }

  .section.section-slider {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  .full-container.landing-1 {
    padding-bottom: 80px;
  }

  .grid.margin-top-120 {
    margin-top: 80px;
  }

  .grid.newsletter-grid {
    margin-top: 60px;
  }

  .display-4 {
    font-size: 44px;
  }

  .grid-quarters {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .paragraph-large {
    font-size: 20px;
  }

  .text-3xl {
    font-size: 28px;
  }

  .text-xl {
    font-size: 24px;
  }

  .text-large {
    font-size: 20px;
  }

  .grid-thirds {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-thirds.listing-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-thirds.spacer-92 {
    grid-row-gap: 24px;
  }

  .grid-thirds.licenses-grid {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .footer-link {
    margin-right: 12px;
    margin-bottom: 12px;
    margin-left: 12px;
  }

  .margin-top-80 {
    margin-top: 60px;
  }

  .flex-stretch-space.footer-template-links-wrap {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .display-2 {
    font-size: 44px;
  }

  .grid-sixths {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-sixths.partners {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .bg-video-hero {
    min-height: 700px;
  }

  .listing-2-content {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .about-card {
    padding: 40px;
  }

  .landing-3-hero {
    height: 550px;
  }

  .landing-3-hero.template-pages {
    height: 450px;
  }

  .gallery-arrow.left {
    bottom: -40px;
  }

  .gallery-arrow.right {
    bottom: -40px;
  }

  .reviews-2-slide {
    padding: 40px;
  }

  .about-hero-image {
    height: 60px;
    min-width: 80px;
    margin-right: 24px;
    margin-left: 24px;
  }

  .about-bg-video {
    height: 550px;
  }

  .about-review-image {
    max-width: 350px;
  }

  .hero-listing-1 {
    padding-bottom: 80px;
  }

  .newsletter-wrap {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .newsletter-form-wrap {
    margin-top: 0px;
  }

  .text-4xl {
    font-size: 32px;
  }

  .subtitle {
    font-size: 24px;
  }

  .listings-sticky-icon.social {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .news-collection-item {
    margin-bottom: 60px;
  }

  .news-info {
    padding: 32px;
  }

  .newsletter-form-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .newsletter-wrap-2 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .news-cms-image {
    margin-top: 60px;
  }

  .stats-block {
    border-right: 1px solid #e0dedc;
    border-bottom: 1px solid #e0dedc;
    border-left: 1px solid #e0dedc;
  }

  .stats-block.block-2 {
    border-top-style: none;
  }

  .stats-block.block-3 {
    border-top-style: none;
  }

  .faq-icon {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .hero-vertical {
    padding-bottom: 80px;
  }
.side_bar.listings_sticky {
	margin-left:0px;
	}
  .careers-cms-image.image-1 {
    width: 40%;
  }

  .careers-cms-image.image-2 {
    width: 33%;
    height: 33%;
  }

  .team-cms-photo {
    width: 450px;
    height: 450px;
  }

  .team-name.back {
    display: none;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .team-cms-photo-mask {
    width: 450px;
    height: 450px;
  }

  .display-3 {
    font-size: 44px;
  }

  .hero-vertical-footer {
    position: static;
  }

  .hero-vertical-footer.contact-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .hero-vertical-footer.team-cms-bottom {
    margin-top: 40px;
  }

  .contact-form-2 {
    padding: 56px 32px 32px;
  }

  .privacy-info {
    margin-top: 60px;
  }

  .pop-up {
    padding-right: 24px;
    padding-left: 24px;
  }

  .navbar-menu-wrap {
    padding-right: 24px;
    padding-left: 24px;
  }

  .navbar-menu-link-text {
    margin-right: 24px;
    margin-left: 24px;
    font-size: 24px;
  }

  .contact-overlay {
    padding-right: 24px;
    padding-left: 24px;
  }

  .showcase-mask {
    height: 600px;
  }

  .about-wrap {
    margin-top: 40px;
  }

  .slider-text {
    width: 80%;
  }

  .licenses-image {
    height: 150px;
  }

  .prodigy-sans {
    font-size: 28px;
  }

  .screenshots-wrap {
    margin-top: 40px;
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  blockquote {
    font-size: 24px;
  }

  .section.section-slider {
    padding-bottom: 140px;
  }

  .full-container.contact-1 {
    padding-top: 120px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .grid.margin-top-120 {
    margin-top: 60px;
  }

  .grid.pop-up-newsletter {
    padding: 56px 24px;
  }

  .line {
    display: none;
  }

  .display-4 {
    font-size: 28px;
  }

  .grid-quarters.partners {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .text-3xl {
    font-size: 24px;
  }

  .text-xl {
    font-size: 20px;
  }

  .grid-thirds.button-divider {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-thirds.navbar-menu-bottom {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .grid-thirds.licenses-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .slider-arrow.left {
    bottom: -20%;
  }

  .slider-arrow.right {
    bottom: -20%;
  }

  .button-cta {
    width: 100%;
  }

  .margin-top-80 {
    margin-top: 60px;
  }

  .badge {
    height: 120px;
  }

  .flex-stretch-space.reviews-2 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .flex-stretch-space.footer-template-links-wrap {
    margin-top: 40px;
  }

  .display-2 {
    font-size: 28px;
  }

  .display-2.margin-top-24 {
    font-size: 28px;
  }

  .listing-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .stats {
    font-size: 28px;
  }

  .ratings-wrap.reviews {
    margin-top: 12px;
  }

  .grid-sixths.partners {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .text-2xl {
    font-size: 20px;
  }

  .stats-item {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .about-card {
    padding-right: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
  }

  .reviews-slide {
    padding: 32px 24px 24px;
  }

  .flex-center {
    width: 100%;
  }

  .flex-center.listing-code {
    margin-top: 12px;
    margin-left: 0px;
  }

  .landing-3-hero {
    height: 400px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .landing-3-hero.template-pages {
    height: 400px;
  }

  .listing-content-2 {
    padding: 24px;
  }

  .stats-2-item {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .gallery-slider {
    height: 200px;
  }

  .gallery-slider-mask {
    height: 200px;
  }

  .reviews-2-slide {
    padding: 32px;
  }

  .about-hero-image {
    width: 40px;
    height: 40px;
    min-width: auto;
    margin-right: 16px;
    margin-left: 16px;
  }

  .about-bg-video {
    height: 350px;
  }

  .hero-listing-1 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .listing-1-filter {
    min-width: auto;
  }

  .newsletter-wrap {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .newsletter-form {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .button-newletter.small {
    padding-right: 16px;
    padding-left: 16px;
  }

  .newsletter-2-wrap {
    padding: 80px 24px;
  }

  .text-4xl {
    font-size: 28px;
  }

  .news-collection-item {
    margin-bottom: 40px;
  }

  .news-info {
    padding: 24px;
  }

  .news-collection-list {
    margin-top: 0px;
  }

  .newsletter-form-wrap-2 {
    margin-top: 0px;
  }

  .news-cms-image {
    margin-top: 40px;
  }

  .careers-image._3 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .stats-block {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .careers-card {
    padding: 24px;
  }

  .hero-vertical {
    padding-top: 120px;
  }

  .hero-vertical.careers-cms {
    padding-top: 160px;
  }

  .team-cms-photo {
    width: 100%;
    height: 100%;
  }

  .team-cms-photo-mask {
    width: 100%;
    height: 100%;
  }

  .display-3 {
    font-size: 28px;
  }

  .grid-fifths.team-social {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .contact-form-2 {
    padding: 40px 24px 24px;
  }

  .contact-form-3 {
    padding: 40px 24px 24px;
  }

  .privacy-info {
    margin-top: 40px;
  }

  .top-bar {
    font-size: 12px;
  }

  .pop-up-close {
    width: 24px;
  }

  .navbar {
    height: auto;
  }
  .page-wrap {
  padding-top: 157px;
}

  .navbar-wrap {
    height: 80px;
  }
  .navbar-wrap img {
  	text-align:center;
	}
  /*.brand-image {
    max-height: 32px;
  }*/

  .navbar-menu-wrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .navbar-menu-link-text {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 20px;
  }

  .badge-nav {
    height: 120px;
  }

  .contact-overlay {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .showcase-mask {
    height: 400px;
  }

  .about-wrap {
    margin-top: 0px;
  }

  .contact-1-hero-bottom {
    margin-top: 32px;
  }

  .listing-price-wrap {
    margin-top: 8px;
  }

  .prodigy-sans {
    font-size: 24px;
  }

  .changelog {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .screenshots-wrap {
    margin-top: 16px;
    grid-row-gap: 60px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

#w-node-_8b31dbcc-c4ac-2b69-af82-8992abd3f95a-e7d46f65 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_030a69a5-dfbc-d9a6-3204-c623f2a58dca-e7d46f65 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_48865b0b-6df2-ba50-9021-dc314bee2f99-e7d46f65 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_48865b0b-6df2-ba50-9021-dc314bee2f9a-e7d46f65 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_48865b0b-6df2-ba50-9021-dc314bee2fa0-e7d46f65 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-f49b2896-a0ac-4406-374a-43aad74a52e1-e7d46f65 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-db3b830e-1f4d-e68c-e11c-131b47387125-e7d46f65 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d9d4d8a9-a034-f6cf-28bd-97d271329019-e7d46f65 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-c48f167a-73c2-2b97-b944-7d1ae3fd04cb-e7d46f65 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d3e41b00-751d-3edf-1ed4-2b83c73a992a-e7d46f65 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_022a1810-9081-b317-dbe1-bd9dde1068c0-e7d46f65 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_022a1810-9081-b317-dbe1-bd9dde1068c1-e7d46f65 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_022a1810-9081-b317-dbe1-bd9dde1068c4-e7d46f65 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_022a1810-9081-b317-dbe1-bd9dde1068c8-e7d46f65 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_022a1810-9081-b317-dbe1-bd9dde1068d7-e7d46f65 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_022a1810-9081-b317-dbe1-bd9dde1068d9-e7d46f65 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_4238f5d6-86e9-2765-4953-3ac963544375-63544333 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4238f5d6-86e9-2765-4953-3ac96354437c-63544333 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_0a037954-63a9-59c3-4688-db0617b795b0-16d46f8b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_8fc058b3-1296-d64b-7b77-e9b079b6b982-16d46f8b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_56b7a4f2-8875-05c9-8432-b5f555879b8e-16d46f8b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_73b53f27-8cd7-ce3a-5740-b092c5bfc1fd-16d46f8b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_0ad992c4-2291-fb43-9d14-583fdee2e40d-53d46f9e {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b6cd6b2f-41fa-faa4-476b-614ec5a1daed-53d46f9e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_702014fd-c825-da30-8fb2-e62dd969bdff-53d46f9e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b6cd6b2f-41fa-faa4-476b-614ec5a1daf4-53d46f9e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5a8289bb-1982-9acd-6a2b-5e1b6296d545-53d46f9e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e4ff90da-3e49-a001-8bdd-a30412f8b937-53d46f9e {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e4ff90da-3e49-a001-8bdd-a30412f8b93c-53d46f9e {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b940e12d-71ed-c154-ba03-0bff4fd101d9-53d46f9e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-a8c38cf6-4d10-c364-44d0-2dc581b78dc1-53d46f9e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-ead02fe9-42d7-65d9-c635-bfe6cc502203-cc5021fb {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ead02fe9-42d7-65d9-c635-bfe6cc502204-cc5021fb {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-ead02fe9-42d7-65d9-c635-bfe6cc502207-cc5021fb {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ead02fe9-42d7-65d9-c635-bfe6cc50220b-cc5021fb, .footer_nav {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ead02fe9-42d7-65d9-c635-bfe6cc50221a-cc5021fb {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ead02fe9-42d7-65d9-c635-bfe6cc50221c-cc5021fb {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-b568bdf7-9387-d5f2-6ced-82a7acbd6673-67d46fb1 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_64900399-4bd6-1d15-32c4-fd8a5d8d8a52-67d46fb1 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_64900399-4bd6-1d15-32c4-fd8a5d8d8a55-67d46fb1 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_77b8393e-ccfd-32b6-1158-ea5321872bb6-67d46fb1 {
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-cd160a8c-ad76-8b32-0818-f54aea376524-86d46fb4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_9c1c1d06-64ec-7da6-5610-9cce59cb5983-86d46fb4 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_22cac98f-fe1d-3226-933b-c97aa80569eb-86d46fb4 {
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_58199b02-e115-8192-6dd1-547ff6a015fb-86d46fb4 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-f93f8851-8f4f-c290-aa39-0155e1cf63cc-86d46fb4 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_5ce4b63c-3a54-c004-b827-63178363324e-86d46fb4 {
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_54c5de47-322b-a7ba-2b38-8f7a68d1bd6a-86d46fb4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_492e2753-f0be-6099-3092-927d569ff8df-86d46fb4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_05f98774-22e8-238b-61cb-696b242995ad-0fd46fb5 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-cef4da9f-f133-d76f-5545-707698d01c82-ecd46fb9 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-d28968e0-46c5-3251-de11-cac70c1bf8de-ecd46fb9 {
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0622654a-a612-0a77-6f1e-8b37694f5436-ecd46fb9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0622654a-a612-0a77-6f1e-8b37694f544c-ecd46fb9 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0622654a-a612-0a77-6f1e-8b37694f5450-ecd46fb9 {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_53fe1924-6d3c-be8f-e55b-30f9ce4046cf-0fd46fba {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_53fe1924-6d3c-be8f-e55b-30f9ce4046d1-0fd46fba {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_53fe1924-6d3c-be8f-e55b-30f9ce4046d3-0fd46fba {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-cdbc305c-c352-a693-9d17-30c0c583cf1c-0fd46fba {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2b526362-7d33-6d28-5b41-b1386c4fbac6-0fd46fba {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_949b7bf9-834e-00de-81bd-d5b797a73727-e0d46fbb {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_949b7bf9-834e-00de-81bd-d5b797a73720-e0d46fbb {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_949b7bf9-834e-00de-81bd-d5b797a7372b-e0d46fbb {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_3dc71c67-8b2d-8859-c5a6-a239ddc0e641-e0d46fbb {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3dc71c67-8b2d-8859-c5a6-a239ddc0e644-e0d46fbb {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_3dc71c67-8b2d-8859-c5a6-a239ddc0e657-e0d46fbb {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_3dc71c67-8b2d-8859-c5a6-a239ddc0e65b-e0d46fbb {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-ec0c8cb6-7cd4-7eb9-3807-c1f9e165433d-a0d46fbc {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_6449e3db-811e-7c20-aad9-a16ca0fcb11c-a0d46fbc {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_56d75a7f-0909-b989-d8f6-2bda368ed0a5-a0d46fbc {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b111364d-31f9-f1c0-afdf-6c242684dc6c-32d46fbd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_3452ae55-f430-0587-57cb-6f3dcd42ef23-32d46fbd {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_71adacaf-40d4-799f-e3d9-0bc6d5d37ff1-32d46fbd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_71adacaf-40d4-799f-e3d9-0bc6d5d38007-32d46fbd {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_71adacaf-40d4-799f-e3d9-0bc6d5d3800b-32d46fbd {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_4cd60e94-da91-aed3-3392-0a2b44213024-d5d46fbe {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_1b3408d2-529e-d63f-c666-a9a6fd67b8ed-d5d46fbe {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-a33d0cbf-60b6-e0cd-9cb3-95655023ca63-d5d46fbe {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_290b25fe-d6ef-3758-a0b2-c4d97d218106-d5d46fbe {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_0479cdf5-c4d5-2430-f951-94df5f207e24-d5d46fbe {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-ed6ffabb-1235-9211-5ce5-63be1a7ad710-d5d46fbe {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-ed6ffabb-1235-9211-5ce5-63be1a7ad714-d5d46fbe {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-b80cb6b5-0d49-111a-849a-2fb15129f0ba-f5d46fbf {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-fe0d6fa0-db62-5c26-aea4-df7cbbe0dc5c-f5d46fbf {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-cbd3dd45-ad2f-fa36-b524-1ba73febc12c-f5d46fbf {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f4d8f46e-7de7-3968-11bb-4a178eb6e02a-58d46fc0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f4d8f46e-7de7-3968-11bb-4a178eb6e041-58d46fc0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f4d8f46e-7de7-3968-11bb-4a178eb6e045-58d46fc0 {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_70523335-140f-cfcb-a5a5-d321af5daca2-58d46fc0 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_01abdef5-cc34-81ff-f2e6-f72219c15cb6-58d46fc0 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e8ad28f2-5878-3e01-ca34-57ec0eb0896d-58d46fc0 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_670c681c-2930-f722-292a-a74ea2283b2e-58d46fc0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_0c715b32-4f51-dd9a-809f-7c1ff754f1d6-58d46fc0 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_268fe6ee-2ebb-bd4f-662e-6179a78d7261-58d46fc0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_268fe6ee-2ebb-bd4f-662e-6179a78d7277-58d46fc0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_268fe6ee-2ebb-bd4f-662e-6179a78d727b-58d46fc0 {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-b568bdf7-9387-d5f2-6ced-82a7acbd6673-f9d46fc1 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_9cdb81d5-caaa-4c40-74a1-925f3772dc49-f9d46fc1 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_9cdb81d5-caaa-4c40-74a1-925f3772dc4c-f9d46fc1 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-a1cea2e5-7ce9-e2d3-c394-d6c648b82287-f9d46fc1 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-a1cea2e5-7ce9-e2d3-c394-d6c648b82290-f9d46fc1 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_920f7cab-d9e3-9487-fd91-18f2e47860ed-f9d46fc1 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_8899ed14-a4a0-6320-5076-a1ad9d42f398-f9d46fc1 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_8899ed14-a4a0-6320-5076-a1ad9d42f3b2-f9d46fc1 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_0c23277e-fe38-9315-38dc-bc323ee99933-f9d46fc1 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_0c23277e-fe38-9315-38dc-bc323ee99936-f9d46fc1 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0c23277e-fe38-9315-38dc-bc323ee99949-f9d46fc1 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0c23277e-fe38-9315-38dc-bc323ee9994d-f9d46fc1 {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_5f05952d-93da-7f95-268a-39d4c5b0cd74-fcd46fc2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e7d4d028-9180-ccea-43c3-676fc4d84797-fcd46fc2 {
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-e7d4d028-9180-ccea-43c3-676fc4d847b6-fcd46fc2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e7d4d028-9180-ccea-43c3-676fc4d847b8-fcd46fc2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ac032139-d6dd-6c9c-9f7b-3c7fd22e4f45-fcd46fc2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_65f7d296-ebcb-141c-bb3b-2a5585703f43-fcd46fc2 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2a866cd6-3f17-c2ec-7fd7-f249ca55f841-fcd46fc2 {
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_7d5a1016-8afe-88b3-4465-1c0903698094-fcd46fc2 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_7d5a1016-8afe-88b3-4465-1c0903698095-fcd46fc2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_7d5a1016-8afe-88b3-4465-1c090369809b-fcd46fc2 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_18be7d48-ddc3-7112-735f-ff0be31d1215-fcd46fc2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_4431db9f-f81d-ab20-3237-38b454dc1368-fcd46fc2 {
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_4431db9f-f81d-ab20-3237-38b454dc136d-fcd46fc2 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4431db9f-f81d-ab20-3237-38b454dc136e-fcd46fc2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_4431db9f-f81d-ab20-3237-38b454dc1374-fcd46fc2 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_8a185917-aa9a-e37b-6800-e0ee07911602-f3d46fdf {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f9b260f1-31e6-079c-7155-789b4a402c15-f3d46fdf {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_611950fe-0a43-9783-5e0b-3606250f4323-f3d46fdf {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-dcd8c551-9e59-a2a1-02ce-50aa9376784e-f3d46fdf {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-dcd8c551-9e59-a2a1-02ce-50aa93767852-f3d46fdf {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-dcd8c551-9e59-a2a1-02ce-50aa93767853-f3d46fdf {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-dcd8c551-9e59-a2a1-02ce-50aa93767857-f3d46fdf {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_92d19c23-ac11-0cbd-14a0-5dcfb22f4554-f3d46fdf {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_97d789b0-d22f-e90e-1948-1e5a2888e307-f3d46fdf {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_62b04672-952e-d750-fa0d-24efe3b6ff0e-f3d46fdf {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_62b04672-952e-d750-fa0d-24efe3b6ff24-f3d46fdf {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_62b04672-952e-d750-fa0d-24efe3b6ff28-f3d46fdf {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-d994c1a4-dd76-9599-63b0-81d6296c3e07-f3d46fdf {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-d994c1a4-dd76-9599-63b0-81d6296c3e09-f3d46fdf {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d994c1a4-dd76-9599-63b0-81d6296c3e0b-f3d46fdf {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_885823ba-347e-7614-3ee0-42f1031114f4-f3d46fdf {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_885823ba-347e-7614-3ee0-42f10311150a-f3d46fdf {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_885823ba-347e-7614-3ee0-42f10311150e-f3d46fdf {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_32dcfbd3-b5c8-19b4-b714-60e4d31d5e10-f3d46fdf {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_32dcfbd3-b5c8-19b4-b714-60e4d31d5e12-f3d46fdf {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_32dcfbd3-b5c8-19b4-b714-60e4d31d5e18-f3d46fdf {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-c7c97550-35be-f9c7-8ca5-fe96bebd45ca-f3d46fdf {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c7c97550-35be-f9c7-8ca5-fe96bebd45e0-f3d46fdf {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c7c97550-35be-f9c7-8ca5-fe96bebd45e4-f3d46fdf {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_99e28239-c22c-52f6-9294-e60853b124da-f3d46fdf {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_99e28239-c22c-52f6-9294-e60853b124de-f3d46fdf {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b568bdf7-9387-d5f2-6ced-82a7acbd6673-5dd46ff2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_11ebff7f-93cb-9c1a-45e6-f1f2e0481bf0-5dd46ff2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_11ebff7f-93cb-9c1a-45e6-f1f2e0481bfd-5dd46ff2 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_11ebff7f-93cb-9c1a-45e6-f1f2e0481bfe-5dd46ff2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_11ebff7f-93cb-9c1a-45e6-f1f2e0481c04-5dd46ff2 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-efce8e4c-c12c-7804-ea91-cb695e0629b6-5dd46ff2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-efce8e4c-c12c-7804-ea91-cb695e0629bb-5dd46ff2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b8c4f664-9f68-ecce-3a11-dbaeff8ed502-5dd46ff2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f429e44b-c430-87b3-26b8-735c96ded2c8-ead47014 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_5c30f910-fde2-ca25-97d1-b83a7d954528-ead47014 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_8c23705b-8b8b-a997-1852-297319d958c3-ead47014 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_7ba298f3-d2f8-3d54-58af-532324cb511a-ead47014 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_64887e44-eade-45d3-a2fe-97ee78e565fc-ead47014 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_39a3a777-5651-800d-1649-f46ded241b52-ead47014 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_02a8b8a3-a07c-2044-c199-9bb50e30335d-ead47014 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_02a8b8a3-a07c-2044-c199-9bb50e30335f-ead47014 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_79eacad7-92b7-8050-1d0b-35ae255b7a6c-ead47014 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_79eacad7-92b7-8050-1d0b-35ae255b7a6e-ead47014 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_2f0dedc6-9568-f375-7f3c-658153c3d6e9-ead47014 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_2f0dedc6-9568-f375-7f3c-658153c3d6eb-ead47014 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-f3e88722-003e-608c-3764-f0c72f6b2a53-ead47014 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-f3e88722-003e-608c-3764-f0c72f6b2a55-ead47014 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_02e248b3-1cb0-6811-6cf2-cf24d301e523-ead47014 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_02e248b3-1cb0-6811-6cf2-cf24d301e525-ead47014 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_02e248b3-1cb0-6811-6cf2-cf24d301e527-ead47014 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_02e248b3-1cb0-6811-6cf2-cf24d301e528-ead47014 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_02e248b3-1cb0-6811-6cf2-cf24d301e529-ead47014 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_02e248b3-1cb0-6811-6cf2-cf24d301e52a-ead47014 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_02e248b3-1cb0-6811-6cf2-cf24d301e52b-ead47014 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_02e248b3-1cb0-6811-6cf2-cf24d301e52c-ead47014 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_4023af60-02f2-8aba-742c-fdb2679b1c5c-ead47014 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-febdba9a-7288-1726-efd1-7e073785531c-ead47014 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-dad87200-6adf-e2ae-c2dc-b1744ac9b5c2-ead47014 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  -ms-grid-column-align: start;
  justify-self: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-c3a379b0-2410-b1b4-e100-43a432c1c485-51d47015 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e307bbbb-6f22-1921-454a-b75d69ecaad0-51d47015 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-e307bbbb-6f22-1921-454a-b75d69ecaad1-51d47015 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e307bbbb-6f22-1921-454a-b75d69ecaad7-51d47015 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5e0950d8-49f3-686b-b7c9-a8e14ad15299-51d47015 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_5e0950d8-49f3-686b-b7c9-a8e14ad152a2-51d47015 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-c6ef1d2d-da1e-2978-4906-a183bc1dce0b-51d47015 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b3c4a4ea-2294-33c7-f8fd-88446962200d-51d47015 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_8953aae0-8445-a178-23bc-e6d87a41055a-51d47015 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_8953aae0-8445-a178-23bc-e6d87a41055b-51d47015 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_8953aae0-8445-a178-23bc-e6d87a410561-51d47015 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_02a07c07-a47f-5c1a-5429-92ff3e03f765-51d47015 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2d30169d-b1ff-25bf-7ad5-400503d809e3-1cd47016 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4be6e772-a9aa-a1d7-1c05-d313fb309ab8-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_862f2bde-9ef7-fd95-19d4-11ed281dc6b3-1cd47016 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_1f0f2331-695a-06b9-fe24-9fc8414f4c25-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_1b987eb5-605c-bf14-4b3d-68dadaa05b05-1cd47016 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-fc4dda13-957e-6633-4713-4de48caba35e-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_6d41bb42-1a9a-91d1-e03c-62f5567d5efb-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_22fd801d-005d-daa3-3fb5-2afebdd01cc0-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-cd9f62a6-e0d8-ee55-e108-64c0616e59de-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_6990df94-be07-09eb-e2e0-f70fd366def6-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-bece6e8a-e650-2cb0-9575-4603293013cc-1cd47016 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-bece6e8a-e650-2cb0-9575-4603293013cd-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-bece6e8a-e650-2cb0-9575-4603293013d3-1cd47016 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-a378b8ea-c32f-da13-ba3e-1fd8b61025e7-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_666b4aed-ac68-abfa-c595-34a16709fcb4-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b8c1316f-28e2-a1a0-0369-6402e1b6f701-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f912ac56-e012-ee30-550a-07089467150d-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d100987-acbc-41ee-9159-9c0828d5122b-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_02265aa8-b57d-8aee-fe60-31381d42ea75-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_610e8883-e2f6-a1f5-15af-b19908be5b7f-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_6a25a7c6-42ae-fada-8c15-c21882be6e7c-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-dc82fbd0-ae58-4f1e-88ea-8f2d1257afd5-1cd47016 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d5a4de7a-90f0-9026-1158-0692cebb5f90-54d47017 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_1b64ba11-83c1-90ac-7f3f-e5a9a0af353a-54d47017 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b41740ba-7294-3fe3-5aaa-3f1e833ad59f-54d47017 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-f24402ac-14b6-b0f2-780a-1e9921e88476-54d47017 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-a2c0e553-f35a-3ab7-ff9f-3042c15b4f33-54d47017 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-fac0c37c-19e8-a1c5-d69a-8f5f050b93ff-54d47017 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-c408f267-1eec-906b-7302-be064815c396-54d47017 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_628977a4-f50a-4773-00ce-016e7ca133cb-54d47017 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_23a85214-70c6-2f5d-5145-9693cf28d06e-54d47017 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-d4ee8a4a-7fcc-978e-c8ad-5ad3c93c58f4-54d47017 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_92935250-84c7-27c3-bf72-bd9a102faf47-54d47017 {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_3723b5be-76be-b2a7-5dcd-a4ebd315a69f-54d47017 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e9057da7-c534-7aa2-1f23-184882c4423f-54d47017 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_9fcaa390-65eb-64bf-d066-ddacfae10bdc-54d47017 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-cdb57825-08ba-1673-9ea5-49c54b641135-54d47017 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-cdb57825-08ba-1673-9ea5-49c54b641137-54d47017 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-cdb57825-08ba-1673-9ea5-49c54b64113a-54d47017 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ad07dedd-babd-a9cc-fcf5-c89fec7a817c-78d4701a {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_07417e22-5df4-40fc-fd45-d1bd0a62880b-78d4701a {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2b86b579-ccf2-5bb0-f037-4022021024af-aed4701b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d533a01e-5761-15e9-5d4a-4046dba81dcd-c3d4701c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2b86b579-ccf2-5bb0-f037-4022021024af-c3d4701c {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b568bdf7-9387-d5f2-6ced-82a7acbd6673-d0d4701d {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_70bde6cb-9a8e-60f9-9722-066ee9b8d4b9-d0d4701d {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_70bde6cb-9a8e-60f9-9722-066ee9b8d4cc-d0d4701d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_70bde6cb-9a8e-60f9-9722-066ee9b8d4cd-d0d4701d {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_70bde6cb-9a8e-60f9-9722-066ee9b8d4d3-d0d4701d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b696ff97-d51f-5869-328b-d40b4c39b7c0-d0d4701d {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b696ff97-d51f-5869-328b-d40b4c39b7de-d0d4701d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b696ff97-d51f-5869-328b-d40b4c39b7df-d0d4701d {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b696ff97-d51f-5869-328b-d40b4c39b7e5-d0d4701d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b568bdf7-9387-d5f2-6ced-82a7acbd6673-68d4701f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e978c68f-36ad-1eea-2493-6174b3051dcc-68d4701f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e978c68f-36ad-1eea-2493-6174b3051dd1-68d4701f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e978c68f-36ad-1eea-2493-6174b3051dd6-68d4701f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3a358c9e-2f13-6a42-4a9f-5455614368b0-68d4701f {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_3a358c9e-2f13-6a42-4a9f-5455614368b5-68d4701f {
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_3a358c9e-2f13-6a42-4a9f-5455614368ba-68d4701f {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-_3a358c9e-2f13-6a42-4a9f-5455614368bd-68d4701f {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_3a358c9e-2f13-6a42-4a9f-5455614368c2-68d4701f {
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-b568bdf7-9387-d5f2-6ced-82a7acbd6673-b2d47020 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_9fd6ce26-2ff6-e306-7927-12dc2839c1ba-3ad47021 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f8e8c591-850a-975c-8f50-1b895683ec9f-3ad47021 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-a5891512-2d0d-bddf-9e73-b653a7ecca4e-3ad47021 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-a5891512-2d0d-bddf-9e73-b653a7ecca52-3ad47021 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-b568bdf7-9387-d5f2-6ced-82a7acbd6673-64d47022 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_28e8e6dc-efff-45a6-9f5a-2aecf0a924f5-64d47022 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_28e8e6dc-efff-45a6-9f5a-2aecf0a924f7-64d47022 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_28e8e6dc-efff-45a6-9f5a-2aecf0a924f9-64d47022 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_28e8e6dc-efff-45a6-9f5a-2aecf0a924fa-64d47022 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_28e8e6dc-efff-45a6-9f5a-2aecf0a924fb-64d47022 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_28e8e6dc-efff-45a6-9f5a-2aecf0a924fc-64d47022 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_28e8e6dc-efff-45a6-9f5a-2aecf0a924fd-64d47022 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_28e8e6dc-efff-45a6-9f5a-2aecf0a924fe-64d47022 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_057baee6-e484-a085-1dee-2ad5d5f1c27d-64d47022 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_54c0f1ad-cd8d-5a0d-70c9-c3ace0e869c2-9ed47023 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_54c0f1ad-cd8d-5a0d-70c9-c3ace0e869d5-9ed47023 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_54c0f1ad-cd8d-5a0d-70c9-c3ace0e869da-9ed47023 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_54c0f1ad-cd8d-5a0d-70c9-c3ace0e869de-9ed47023 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_5d5a50de-300a-c483-9395-d429a8c6acb5-a8c6acb3 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_5d5a50de-300a-c483-9395-d429a8c6acba-a8c6acb3 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#Pop-Up-Newsletter-2.w-node-_5d5a50de-300a-c483-9395-d429a8c6acbc-a8c6acb3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_5d5a50de-300a-c483-9395-d429a8c6acbd-a8c6acb3 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-e06e2918-2d8a-7dbb-dbbc-85f9da40814c-ccd47024 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-dd62b96d-a990-df23-3ae9-544a2a28b9b4-ccd47024 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e06e2918-2d8a-7dbb-dbbc-85f9da408153-ccd47024 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_883a0f0f-76e9-c369-baab-87dc2ef4807e-ccd47024 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b568bdf7-9387-d5f2-6ced-82a7acbd6673-add47025 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_56e66b03-db0d-c376-f567-5d589877c812-add47025 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_391a71c4-77fd-c6c5-0543-77f03afda005-add47025 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_391a71c4-77fd-c6c5-0543-77f03afda008-add47025 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  -ms-grid-column-align: start;
  justify-self: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_0a6d8ee7-b8ad-0203-cc60-bf65f313e4df-add47025 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_0a6d8ee7-b8ad-0203-cc60-bf65f313e4e4-add47025 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_1bfd9206-6d95-be4e-4310-12b63c238b92-a9d47028 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-a107360e-bcf9-2f52-7a9c-ac6a77cac9bf-a9d47028 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-b488d652-dfc5-6b2a-5d50-d9eb5a155d90-a9d47028 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_99778d03-37d4-65c5-e0bb-c97fa8777071-a9d47028 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_4fe23948-9b10-a141-2f96-487e838177ed-a9d47028 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_0f803d7c-16e9-9b2a-49a8-70af36856a2b-a9d47028 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-c4865ce3-39b2-f196-7385-3086a55f9df3-a9d47028 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_386d71dd-40db-fb27-c074-ce9095493462-a9d47028 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_9a1f8a86-83f6-336a-07e3-e3c36360aa91-a9d47028 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_4c3fc397-7a77-6053-c0c2-49ad73b02797-a9d47028 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_3bd47378-3a90-324b-7aee-7ff3274280ba-02d47029 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b568bdf7-9387-d5f2-6ced-82a7acbd6673-77d4702e {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_4fca7299-ef9c-68ec-4866-9aec6584cb90-77d4702e {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b51164c3-f902-9541-ea7e-11cff34f0874-77d4702e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f73b-77d4702e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f73e-77d4702e {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f744-77d4702e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f746-77d4702e {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f748-77d4702e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f74c-77d4702e {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f74e-77d4702e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f752-77d4702e {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f754-77d4702e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f758-77d4702e {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f75a-77d4702e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f75e-77d4702e {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f760-77d4702e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_6b1be7df-6677-0e19-0c58-69dfb1d7d1ba-77d4702e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b48-77d4702e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b4b-77d4702e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_2ca8b3b1-52e7-9115-0435-f69bda00322d-77d4702e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_2ca8b3b1-52e7-9115-0435-f69bda00322e-77d4702e {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2ca8b3b1-52e7-9115-0435-f69bda003230-77d4702e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_2ca8b3b1-52e7-9115-0435-f69bda003232-77d4702e {
  -ms-grid-column-align: center;
  justify-self: center;
}

@media screen and (max-width: 991px) {
  #w-node-_022a1810-9081-b317-dbe1-bd9dde1068d5-e7d46f65 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_022a1810-9081-b317-dbe1-bd9dde1068d9-e7d46f65 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_41a0e0e2-4c1a-12f0-610c-2b650ef5251a-53d46f9e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_41b4d9bc-fd89-a627-d3d9-e1e68e9d159a-53d46f9e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_9c0b8da7-dd83-53d2-40c5-cdd1c0f560d3-53d46f9e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_5a8289bb-1982-9acd-6a2b-5e1b6296d545-53d46f9e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_45fd8429-e041-34d2-0e76-7b8f89917a1d-53d46f9e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-ae2ee40e-b546-a531-0172-a039c8d1e525-53d46f9e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-cdd36435-966b-e8f7-04ca-448ed2837e04-53d46f9e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-a8c38cf6-4d10-c364-44d0-2dc581b78dc1-53d46f9e {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-ead02fe9-42d7-65d9-c635-bfe6cc502218-cc5021fb {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-ead02fe9-42d7-65d9-c635-bfe6cc50221c-cc5021fb {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_77b8393e-ccfd-32b6-1158-ea5321872bb6-67d46fb1 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_77b8393e-ccfd-32b6-1158-ea5321872bbb-67d46fb1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_77b8393e-ccfd-32b6-1158-ea5321872bbc-67d46fb1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_77b8393e-ccfd-32b6-1158-ea5321872be4-67d46fb1 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d28968e0-46c5-3251-de11-cac70c1bf8de-ecd46fb9 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_63a6b322-356a-dd13-9e48-565fab31761e-ecd46fb9 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_2bf35674-ea39-6473-cb0d-b223431bfb35-ecd46fb9 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c303e1a4-562b-6288-fded-088473ea300b-ecd46fb9 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_4cd60e94-da91-aed3-3392-0a2b44213024-d5d46fbe {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_290b25fe-d6ef-3758-a0b2-c4d97d218109-d5d46fbe {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-cbd3dd45-ad2f-fa36-b524-1ba73febc12c-f5d46fbf {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_0c715b32-4f51-dd9a-809f-7c1ff754f1d9-58d46fc0 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #Newsletter-name.w-node-_9cdb81d5-caaa-4c40-74a1-925f3772dc4f-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #newsletter-email.w-node-_9cdb81d5-caaa-4c40-74a1-925f3772dc50-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_9cdb81d5-caaa-4c40-74a1-925f3772dc51-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-a1cea2e5-7ce9-e2d3-c394-d6c648b82290-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920f7cab-d9e3-9487-fd91-18f2e47860e5-f9d46fc1 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920f7cab-d9e3-9487-fd91-18f2e47860ed-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920f7cab-d9e3-9487-fd91-18f2e47860f7-f9d46fc1 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-b8c4f664-9f68-ecce-3a11-dbaeff8ed505-5dd46ff2 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_7ba298f3-d2f8-3d54-58af-532324cb511a-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-bddd9669-29bd-8ecd-5c99-b072c19d7220-ead47014 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_4023af60-02f2-8aba-742c-fdb2679b1c5c-ead47014 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-febdba9a-7288-1726-efd1-7e073785531c-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-dad87200-6adf-e2ae-c2dc-b1744ac9b5c2-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-f4daceb1-32ac-3d6c-1a3d-b97d68c3f8fa-51d47015 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_2de20d15-99de-472c-d48c-f967750e549a-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-d3f34f02-a8b5-df0c-e66b-2c93fff7fe5c-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5a493c60-a369-1154-d0e3-fa549f2168bd-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-b59bbeb6-e97d-6d8a-bf7e-9be5b98bc546-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-b59bbeb6-e97d-6d8a-bf7e-9be5b98bc548-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-b59bbeb6-e97d-6d8a-bf7e-9be5b98bc54d-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_59212c4a-2966-c768-44aa-740e9027bf97-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_59212c4a-2966-c768-44aa-740e9027bf99-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_59212c4a-2966-c768-44aa-740e9027bf9e-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_9e6479f3-7c2b-c3fe-4c6a-5056c7b3a5fb-1cd47016 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-b3b150f8-a7e3-b4e2-280c-a01f21d459b9-1cd47016 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_77e5a4f8-291a-d774-7f89-bb6f793f859a-1cd47016 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-e9d72f43-fbef-c9db-5ae1-97d52affab8e-1cd47016 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-ba8a38dc-dd70-2a14-73f2-3a542d98e2f9-54d47017 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_94e3eb8b-7afb-c8db-08ed-35aab7d2b999-54d47017 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-cdb57825-08ba-1673-9ea5-49c54b64113c-54d47017 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-b72befb3-257e-95a4-1940-8471c34a7bc1-54d47017 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #Newsletter-name.w-node-_61610ac1-db40-726b-4998-ce57070896b7-78d4701a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #newsletter-email.w-node-_61610ac1-db40-726b-4998-ce57070896ba-78d4701a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_61610ac1-db40-726b-4998-ce57070896bb-78d4701a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_5a29d98a-8d34-0616-451c-be865ec17a51-aed4701b {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d533a01e-5761-15e9-5d4a-4046dba81dcd-aed4701b {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_2b86b579-ccf2-5bb0-f037-4022021024af-aed4701b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_91cb8da7-d653-4c69-6e4a-be37f6db3a86-aed4701b {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d97ef78b-5344-16f1-5ead-278b17829b90-c3d4701c {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_2b86b579-ccf2-5bb0-f037-4022021024af-c3d4701c {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_70bde6cb-9a8e-60f9-9722-066ee9b8d4bc-d0d4701d {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-b696ff97-d51f-5869-328b-d40b4c39b7c3-d0d4701d {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6f121bdd-e8dd-81b7-729d-373695a35457-3ad47021 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_391a71c4-77fd-c6c5-0543-77f03afda005-add47025 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_391a71c4-77fd-c6c5-0543-77f03afda008-add47025 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_0a6d8ee7-b8ad-0203-cc60-bf65f313e4e4-add47025 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_6af1dd23-61a9-c7a4-69e8-74c5fb9bb71c-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_1bfd9206-6d95-be4e-4310-12b63c238b92-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-a107360e-bcf9-2f52-7a9c-ac6a77cac9bc-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-a107360e-bcf9-2f52-7a9c-ac6a77cac9bf-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-b488d652-dfc5-6b2a-5d50-d9eb5a155d8d-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-b488d652-dfc5-6b2a-5d50-d9eb5a155d90-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_4c3fc397-7a77-6053-c0c2-49ad73b02794-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_4c3fc397-7a77-6053-c0c2-49ad73b02797-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_4fca7299-ef9c-68ec-4866-9aec6584cb94-77d4702e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_4fca7299-ef9c-68ec-4866-9aec6584cb9a-77d4702e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_4fca7299-ef9c-68ec-4866-9aec6584cba0-77d4702e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-b51164c3-f902-9541-ea7e-11cff34f0873-77d4702e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-b51164c3-f902-9541-ea7e-11cff34f0874-77d4702e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f744-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_6b1be7df-6677-0e19-0c58-69dfb1d7d1a8-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6b1be7df-6677-0e19-0c58-69dfb1d7d1ae-77d4702e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6b1be7df-6677-0e19-0c58-69dfb1d7d1b4-77d4702e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6b1be7df-6677-0e19-0c58-69dfb1d7d1ba-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6b1be7df-6677-0e19-0c58-69dfb1d7d1bd-77d4702e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6b1be7df-6677-0e19-0c58-69dfb1d7d1c3-77d4702e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6b1be7df-6677-0e19-0c58-69dfb1d7d1c9-77d4702e {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b4d-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b4f-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b54-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b5a-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b5c-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b61-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b67-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b69-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b6e-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
}

@media screen and (max-width: 767px) {
  #w-node-b940e12d-71ed-c154-ba03-0bff4fd101d9-53d46f9e {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-a8c38cf6-4d10-c364-44d0-2dc581b78dc1-53d46f9e {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_9c1c1d06-64ec-7da6-5610-9cce59cb5983-86d46fb4 {
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_22cac98f-fe1d-3226-933b-c97aa80569eb-86d46fb4 {
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_58199b02-e115-8192-6dd1-547ff6a015fb-86d46fb4 {
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
  }

  #w-node-f93f8851-8f4f-c290-aa39-0155e1cf63cc-86d46fb4 {
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_5ce4b63c-3a54-c004-b827-63178363324e-86d46fb4 {
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_53fe1924-6d3c-be8f-e55b-30f9ce4046cf-0fd46fba {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_53fe1924-6d3c-be8f-e55b-30f9ce4046d3-0fd46fba {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_949b7bf9-834e-00de-81bd-d5b797a73727-e0d46fbb {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_949b7bf9-834e-00de-81bd-d5b797a73720-e0d46fbb {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_949b7bf9-834e-00de-81bd-d5b797a7372b-e0d46fbb {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-row: 3;
    grid-row-start: 3;
  }

  #w-node-_3dc71c67-8b2d-8859-c5a6-a239ddc0e644-e0d46fbb {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_3dc71c67-8b2d-8859-c5a6-a239ddc0e646-e0d46fbb {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_3dc71c67-8b2d-8859-c5a6-a239ddc0e657-e0d46fbb {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_3dc71c67-8b2d-8859-c5a6-a239ddc0e65b-e0d46fbb {
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_70523335-140f-cfcb-a5a5-d321af5daca2-58d46fc0 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_01abdef5-cc34-81ff-f2e6-f72219c15cb6-58d46fc0 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-e8ad28f2-5878-3e01-ca34-57ec0eb0896d-58d46fc0 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #Newsletter-name.w-node-_9cdb81d5-caaa-4c40-74a1-925f3772dc4f-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #newsletter-email.w-node-_9cdb81d5-caaa-4c40-74a1-925f3772dc50-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_0c23277e-fe38-9315-38dc-bc323ee99936-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_0c23277e-fe38-9315-38dc-bc323ee99938-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_0c23277e-fe38-9315-38dc-bc323ee99949-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_0c23277e-fe38-9315-38dc-bc323ee9994d-f9d46fc1 {
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d994c1a4-dd76-9599-63b0-81d6296c3e07-f3d46fdf {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d994c1a4-dd76-9599-63b0-81d6296c3e0b-f3d46fdf {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_32dcfbd3-b5c8-19b4-b714-60e4d31d5e10-f3d46fdf {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_32dcfbd3-b5c8-19b4-b714-60e4d31d5e12-f3d46fdf {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_32dcfbd3-b5c8-19b4-b714-60e4d31d5e18-f3d46fdf {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-row: 3;
    grid-row-start: 3;
  }

  #w-node-_5c30f910-fde2-ca25-97d1-b83a7d954528-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_8c23705b-8b8b-a997-1852-297319d958c3-ead47014 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row-align: end;
    align-self: end;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_7ba298f3-d2f8-3d54-58af-532324cb511a-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-bddd9669-29bd-8ecd-5c99-b072c19d7220-ead47014 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-febdba9a-7288-1726-efd1-7e073785531c-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-dad87200-6adf-e2ae-c2dc-b1744ac9b5c2-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }

  #w-node-d3f34f02-a8b5-df0c-e66b-2c93fff7fe5c-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_5a493c60-a369-1154-d0e3-fa549f2168bd-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-b59bbeb6-e97d-6d8a-bf7e-9be5b98bc548-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-b59bbeb6-e97d-6d8a-bf7e-9be5b98bc54d-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_59212c4a-2966-c768-44aa-740e9027bf99-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_59212c4a-2966-c768-44aa-740e9027bf9e-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #Newsletter-name.w-node-_61610ac1-db40-726b-4998-ce57070896b7-78d4701a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #newsletter-email.w-node-_61610ac1-db40-726b-4998-ce57070896ba-78d4701a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_3a358c9e-2f13-6a42-4a9f-5455614368b0-68d4701f {
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_3a358c9e-2f13-6a42-4a9f-5455614368b5-68d4701f {
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_3a358c9e-2f13-6a42-4a9f-5455614368ba-68d4701f {
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
  }

  #w-node-_3a358c9e-2f13-6a42-4a9f-5455614368bd-68d4701f {
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_3a358c9e-2f13-6a42-4a9f-5455614368c2-68d4701f {
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #name.w-node-a5891512-2d0d-bddf-9e73-b653a7ecca50-3ad47021 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-a5891512-2d0d-bddf-9e73-b653a7ecca52-3ad47021 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_391a71c4-77fd-c6c5-0543-77f03afda005-add47025 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_391a71c4-77fd-c6c5-0543-77f03afda008-add47025 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }

  #w-node-_0a6d8ee7-b8ad-0203-cc60-bf65f313e4df-add47025 {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_0a6d8ee7-b8ad-0203-cc60-bf65f313e4e4-add47025 {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6af1dd23-61a9-c7a4-69e8-74c5fb9bb71c-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_1bfd9206-6d95-be4e-4310-12b63c238b92-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-a107360e-bcf9-2f52-7a9c-ac6a77cac9bc-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-a107360e-bcf9-2f52-7a9c-ac6a77cac9bf-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-b488d652-dfc5-6b2a-5d50-d9eb5a155d8d-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b488d652-dfc5-6b2a-5d50-d9eb5a155d90-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_4c3fc397-7a77-6053-c0c2-49ad73b02794-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4c3fc397-7a77-6053-c0c2-49ad73b02797-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-b51164c3-f902-9541-ea7e-11cff34f0873-77d4702e {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f73b-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f73e-77d4702e {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row-align: end;
    align-self: end;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f744-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b4f-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b54-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b5c-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b61-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b69-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b6e-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_48865b0b-6df2-ba50-9021-dc314bee2f9a-e7d46f65 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4238f5d6-86e9-2765-4953-3ac963544372-63544333 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_4238f5d6-86e9-2765-4953-3ac963544375-63544333 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_4238f5d6-86e9-2765-4953-3ac96354437c-63544333 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_05f98774-22e8-238b-61cb-696b242995ad-0fd46fb5 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-cef4da9f-f133-d76f-5545-707698d01c82-ecd46fb9 {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }

  #w-node-_670c681c-2930-f722-292a-a74ea2283b2e-58d46fc0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #Newsletter-name.w-node-_9cdb81d5-caaa-4c40-74a1-925f3772dc4f-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #newsletter-email.w-node-_9cdb81d5-caaa-4c40-74a1-925f3772dc50-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_9cdb81d5-caaa-4c40-74a1-925f3772dc51-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-a1cea2e5-7ce9-e2d3-c394-d6c648b82290-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920f7cab-d9e3-9487-fd91-18f2e47860e5-f9d46fc1 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_920f7cab-d9e3-9487-fd91-18f2e47860ed-f9d46fc1 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_7d5a1016-8afe-88b3-4465-1c0903698095-fcd46fc2 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4431db9f-f81d-ab20-3237-38b454dc136e-fcd46fc2 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b1ea70cf-cc05-09eb-cea2-62b7c8a8dc59-f3d46fdf {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_97d789b0-d22f-e90e-1948-1e5a2888e307-f3d46fdf {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }

  #w-node-_5c30f910-fde2-ca25-97d1-b83a7d954528-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_8c23705b-8b8b-a997-1852-297319d958c3-ead47014 {
    -ms-grid-row-align: end;
    align-self: end;
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_7ba298f3-d2f8-3d54-58af-532324cb511a-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_39a3a777-5651-800d-1649-f46ded241b52-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_02a8b8a3-a07c-2044-c199-9bb50e30335f-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_79eacad7-92b7-8050-1d0b-35ae255b7a6e-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_2f0dedc6-9568-f375-7f3c-658153c3d6eb-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-f3e88722-003e-608c-3764-f0c72f6b2a55-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_4023af60-02f2-8aba-742c-fdb2679b1c5c-ead47014 {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-febdba9a-7288-1726-efd1-7e073785531c-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-dad87200-6adf-e2ae-c2dc-b1744ac9b5c2-ead47014 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_5e0950d8-49f3-686b-b7c9-a8e14ad15299-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_5e0950d8-49f3-686b-b7c9-a8e14ad152a2-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_2de20d15-99de-472c-d48c-f967750e549a-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d3f34f02-a8b5-df0c-e66b-2c93fff7fe5c-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_5a493c60-a369-1154-d0e3-fa549f2168bd-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b59bbeb6-e97d-6d8a-bf7e-9be5b98bc546-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b59bbeb6-e97d-6d8a-bf7e-9be5b98bc548-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b59bbeb6-e97d-6d8a-bf7e-9be5b98bc54d-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_59212c4a-2966-c768-44aa-740e9027bf97-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_59212c4a-2966-c768-44aa-740e9027bf99-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_59212c4a-2966-c768-44aa-740e9027bf9e-51d47015 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_1f0f2331-695a-06b9-fe24-9fc8414f4c25-1cd47016 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_6b2106aa-11c4-3168-0168-4d9b94bfa899-78d4701a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #Newsletter-name.w-node-_61610ac1-db40-726b-4998-ce57070896b7-78d4701a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #newsletter-email.w-node-_61610ac1-db40-726b-4998-ce57070896ba-78d4701a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_61610ac1-db40-726b-4998-ce57070896bb-78d4701a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d533a01e-5761-15e9-5d4a-4046dba81dcd-aed4701b {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_2b86b579-ccf2-5bb0-f037-4022021024af-aed4701b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_2b86b579-ccf2-5bb0-f037-4022021024af-c3d4701c {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_70bde6cb-9a8e-60f9-9722-066ee9b8d4cd-d0d4701d {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_391a71c4-77fd-c6c5-0543-77f03afda005-add47025 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_391a71c4-77fd-c6c5-0543-77f03afda008-add47025 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_786f4410-4873-8fd1-1262-fad13ae25c3b-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_25ca334c-b281-0411-0376-a18b804a383f-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-a107360e-bcf9-2f52-7a9c-ac6a77cac9bc-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-a107360e-bcf9-2f52-7a9c-ac6a77cac9bf-a9d47028 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-b51164c3-f902-9541-ea7e-11cff34f0874-77d4702e {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f73b-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f73e-77d4702e {
    -ms-grid-row-align: end;
    align-self: end;
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f744-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f748-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f74e-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f754-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f75a-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-b53f4578-f7cc-fafd-e9e3-a62fc3c8f760-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b48-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b4b-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b4d-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b4f-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b54-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b5a-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b5c-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b61-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b67-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b69-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c43711b4-d2ce-21ee-c2c0-c3efb0f16b6e-77d4702e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

@font-face {
  font-family: 'Prodigysans';
  src: url('../fonts/ProdigySans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Prodigysans';
  src: url('../fonts/ProdigySans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mayenne sans web';
  src: url('../fonts/Mayenne_Sans-Regular_web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Prodigysans';
  src: url('../fonts/ProdigySans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}